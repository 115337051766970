.container {
  padding: 0 1rem;
  height: auto;
  border-radius: 0.5rem;
}

.title {
  font-size: 1rem;
  padding: 1rem 0;
  color: var(--gray-800);
}
.wrapper_drop {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10rem;
  div {
    width: 100%;
  }
}

.drop_zone {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 8rem;
  cursor: pointer;
  border: 1px var(--gray-400) dashed;
  border-radius: 0.5rem;
  width: 80%;
  color: var(--gray-400);
  font-size: 0.85rem;
  margin: 1rem 0;
  &:hover {
    color: var(--gray-500);

    border: 1px var(--gray-500) dashed;
  }
}
.wrapper_radio_ {
  display: flex;
  flex-direction: column;
}
.wrapper_radio {
  display: flex;
  align-items: center;
  gap: 1rem;

  p {
    display: flex;
    gap: 0.3rem;
    margin: 0;
    font-size: 0.85rem;
  }
}
.drop {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.wrapper_form_button {
  display: grid;
  grid-template-columns: 1fr 18rem;
  align-items: center;
  gap: 1rem;
}
.box_file {
  border: 1px solid var(--gray-200);
  padding: 1rem;
  margin: 1rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: flex-start;
  height: 6rem;
  div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    h3 {
      font-size: 0.8rem;
      color: var(--gray-800);
    }
  }
  button {
    background: transparent;

    svg {
      color: var(--gray-800);
    }
  }
}
.form {
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.form_wrapper {
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
}
.buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
}
.buttons_ {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}
.button {
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  background: var(--primary-700);
  color: var(--white);

  &:disabled {
    opacity: 0.5;
  }
}

.button_cancel {
  padding: 0.3rem 1rem;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  background: transparent;
  color: var(--primary-700);
  border-bottom: 0.1875rem solid var(--primary-700);
  &:disabled {
    opacity: 0.5;
  }
}

.button_proof {
  @extend .button;
  background: var(--white);
  border: 2px solid var(--primary-700);
  color: var(--primary-700);
}
.select {
  height: 2rem;
  min-width: 12rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 1rem;
  margin-top: 1.2rem;
  border-radius: 0.5rem;
  border: 1px solid var(--gray-100);
  position: relative;
  select {
    all: initial;
    width: 100%;
    z-index: 2;
    font-family: 'Roboto', sans-serif;
    font-size: 0.9rem;
    color: var(--gray-200);
  }
  svg {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
}

.description_payment {
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
}

.description_finish {
  display: flex;
  padding: 3rem 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;

  h2 {
    font-weight: 600;
    font-size: 32px;
    color: var(--gray-800);
  }
  p {
    text-align: center;
    line-height: 1.5rem;
    color: var(--gray-300);
  }
}
.buttons_finish {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}
.values {
  display: flex;
  flex-direction: column;
  p {
    font-size: 0.8rem;
    color: var(--gray-400);
  }
}
.line {
  border: 1px solid var(--gray-50);
  border-radius: 0.1rem;
  margin: 0.3rem 0;
}
.values_ {
  display: flex;
  gap: 1rem;
  justify-content: space-between;

  span {
    margin-top: 0.3rem;
    font-size: 1rem;
    margin-right: 0;
    color: var(--gray-400);
  }
}

.values_total {
  @extend .values_;

  span {
    color: var(--gray-600);
    font-weight: 600;
  }
}
.modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
}
.modal_content {
  border-radius: 0.5rem;
  width: 32rem;
  height: 14rem;
  padding: 0 1rem;
  margin: auto;
  background-color: var(--white);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  gap: 1rem;
  h2 {
    font-size: 1.5rem;
    color: var(--gray-400);
    font-weight: bold;
    margin: 0;
  }
  p {
    font-size: 0.85rem;
    color: var(--gray-400);
  }
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    button {
      padding: 0.5rem 1rem;
      width: 14rem;
      height: 2.5rem;
      font-size: 0.85rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: var(--white);
      background-color: var(--primary);
      border-radius: 0.5rem;
      border: none;
      cursor: pointer;
      &:hover {
        opacity: 0.5;
      }
    }
    .cancel {
      background: var(--red-500);
    }
  }
}

/* info */

.header_info {
  display: flex;
  gap: 1rem;
  padding: 1rem 0;
  width: 100%;
  align-items: center;
  min-width: 20rem;
}

.header_info_wrapper {
  display: flex;
  gap: 1rem;
  padding: 1rem 0;
  width: 100%;
  min-width: 20rem;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.title_info {
  font-size: 0.75rem;
  font-weight: 300;
}

.title_description {
  font-weight: 600;
  font-size: 1.1838rem;
}

.line_info {
  margin: 0;
  min-width: 20rem;
}

.box_info {
  min-width: 30rem;
  background: var(--gray-50);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 0.5rem;

  span {
    display: flex;
    gap: 0.5rem;
    font-size: 0.75rem;
    font-weight: 600;

    p {
      font-weight: 300;
      font-size: 0.75rem;
    }
  }
}

.block {
  border: 0.0625rem solid var(--gray-50);
  border-radius: 0.1rem;
  margin: 0.3rem 0;
}

/* values */
.values_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 4rem;
}
.values {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}
.fee {
  display: flex;
  gap: 0.3rem;
  span {
    font-size: 0.75rem;
    font-weight: 300;
  }
  p {
    font-size: 0.8rem;
    font-weight: 600;
  }
}
.title_amount {
  font-size: 1rem;
  font-weight: 400;
}
.amount {
  font-size: 2rem;
  font-weight: 600;
}
.line {
  border: 0.0625rem solid var(--gray-50);
  border-radius: 0.1rem;
  margin: 0.3rem 0;
}

.values_ {
  display: flex;
  gap: 1rem;
  justify-content: space-between;

  span {
    margin-top: 0.5rem;
    font-size: 1rem;
    margin-right: 0;
    color: var(--gray-400);
  }
}

.alert {
  border: 3px solid #ffc0c0;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  width: 100%;
  min-width: 20rem;
  background: #fff0f0;
  strong {
    font-weight: 600;
  }
  span {
    display: flex;
    gap: 0.5rem;
  }
  p {
    font-weight: 600;
    color: #ff0000;
  }
  button {
    margin-left: auto;
    background: #ff0000;
    border: none;
    border-radius: 1rem;
    padding: 0.5rem 1rem;
    font-size: 0.75rem;
    font-weight: 600;
    color: var(--white);
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }
}
.error {
  color: var(--red-500);
  font-size: 0.7rem;

  @media screen and (max-width: 767px) {
    margin-top: 0.5rem;
  }
}
