.container {
  display: flex;
  width: 50rem;

  gap: 1rem;
}

//details
.box_description {
  border: 1px solid var(--gray-50);
  border-radius: 0.5rem;
  display: flex;
  width: 28rem;
  padding: 1rem;
  flex-direction: column;
  gap: 0.5rem;

  span {
    display: flex;
    gap: 0.5rem;
    font-weight: 600;
    font-size: 1rem;
    color: var(--gray-600);
    p {
      color: #777777;
      font-size: 14px;
      font-weight: bold;
      margin-right: 8px;
    }
  }
}

//resume
.box_resume {
  border: 1px solid var(--gray-50);
  border-radius: 0.5rem;
  display: flex;
  width: 28rem;
  padding: 1rem;
  flex-direction: column;
  gap: 0.5rem;
}
.summary-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  p {
    color: #777777;
    font-size: 14px;
    font-weight: bold;
    margin-right: 8px;
  }

  span {
    font-weight: 600;
    font-size: 1rem;
    color: var(--green-text);
  }
}
.summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  p {
    color: #777777;
    font-size: 14px;
    font-weight: bold;
    margin-right: 8px;
  }

  span {
    font-weight: 600;
    font-size: 1rem;
    color: var(--primary);
  }
}
.summary-item-process {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  p {
    color: #777777;
    font-size: 14px;
    font-weight: bold;
    margin-right: 8px;
  }

  span {
    font-weight: 600;
    font-size: 1rem;
    color: var(--blue-dark-text);
  }
}
.summary-item-error {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  p {
    color: #777777;
    font-size: 14px;
    font-weight: bold;
    margin-right: 8px;
  }

  span {
    font-weight: 600;
    font-size: 1rem;
    color: var(--red-text);
  }
}
.summary-item-await {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  p {
    color: #777777;
    font-size: 14px;
    font-weight: bold;
    margin-right: 8px;
  }

  span {
    font-weight: 600;
    font-size: 1rem;
    color: var(--orange-text);
  }
}
