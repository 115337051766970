.container {
  background-color: #ffffff;
  background-image: url('../../Assets/bg.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100vh;
  //height: calc(100vh - 5rem);
  margin-top: -5rem;

  div:nth-child(1) {
    height: 100%;
    width: 80%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    div:nth-child(1) {
      width: 33rem;
      height: 20rem;
      border-radius: 1rem;
      background: var(--white);

      div {
        width: 32%;
        height: 100%;
        border-radius: 1rem 0 0 1rem;

        background: var(--primary);
      }
      form {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        padding: 2rem;
        label {
          color: var(--gray-800);
          font-size: 2rem;
          margin-bottom: 1rem;
          text-align: left;
        }

        input {
          margin: 0.7rem 0;
          width: 100%;
          font-size: 1rem;
          line-height: 1.3;
          border: 1px solid var(--purple-200);
          border-radius: 0.5rem;
          outline: none;
          padding: 4px 6px;
          height: 2.4rem;

          &:focus {
            border: 2px solid var(--primary);
            transition: all 0.1s ease;
          }
          &:hover {
            border: 2px solid var(--purple-400);
            transition: all 0.1s ease;
          }
        }
        button {
          margin-top: 0.5rem;
          border-radius: 8px;
          width: 6rem;
          padding: 10px;
          color: var(--white);
          background: var(--primary);

          &:hover {
            background: var(--purple-800);
          }
        }

        a {
          margin-top: 1rem;
          color: var(--primary);

          &:hover {
            color: var(--purple-200);
          }
        }
      }
    }
  }
}

@media (max-width: 420px) {
  .container {
    div:nth-child(1) {
      div:nth-child(1) {
        div {
          display: none;
        }
      }
    }
  }
}

.loading {
  cursor: wait;
  opacity: 0.4;
}

.shadow {
  box-shadow: 13px 13px 26px #b5b3b3, -13px -13px 26px #ffffff;
}

.logo {
  width: 5rem;
  height: 5rem;
}

.login {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  height: 1rem;
  width: 6rem;

  a {
    margin-left: auto;
  }
  svg {
    margin-top: 1rem;
    color: var(--primary);
  }
}

.error {
  width: calc(100%);
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  color: var(--white);
  height: 1.1rem;
  position: absolute;
  border-radius: 0 0 1rem 1rem;
}
