@import '../../styles/_global';

.container {
  @extend .container_style;
  display: flex;
  flex-direction: column;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.header_style_ {
  @extend .header_style;
  padding: 0 1rem;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}
.title_list {
  height: 4.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  header {
    display: flex;
    gap: 1rem;
    width: 36rem;
    h3 {
      font-size: 1.5rem !important;
      font-weight: 500;
      color: var(--gray-900) !important;
    }
    p {
      background: var(--gray-10);
      font-weight: 500;
      height: 2rem;
      width: auto;
      padding: 0 0.8rem;
      border-radius: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.title {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--gray-600);
  padding: 1rem 2rem;
}

.form {
  margin: 2rem;
  display: flex;
  height: 4rem;
  justify-content: flex-end;
  align-items: center;
  border-radius: 0.5rem;
  gap: 1rem;
  background-color: var(--white);
}

@media (max-width: 420px) {
  .container {
    width: 100vw;
    margin-left: -2rem;
  }
}

.wrapper__filters__mobile {
  display: none;

  @media (max-width: 420px) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.wrapper__filters {
  display: flex;

  @media (max-width: 420px) {
    display: none;
  }
}

.input__date {
  input[type='date'] {
    background: var(--primary);
    padding: 0.7rem 2rem;
    outline: none;
    border: none;
    border-radius: 0.5rem;
    color: var(--white);
    font-size: 16px;

    &::-webkit-calendar-picker-indicator {
      background-color: var(--primary);
      padding: 0.2rem;
      border-radius: 0.2rem;
      margin-left: 1rem;
      width: 20px;
      height: 20px;
    }
  }
}
.send_approve {
  @include button_();
  font-size: 0.9rem;
  border-radius: 1rem;
  &:hover {
    opacity: 0.9;
    color: var(--white);
  }
}
.wrapper_acions {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-left: auto;
}
.select_Reload {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  border-radius: 1rem;
}

.wrapper_cards {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 1rem;
  padding: 1rem 1.5rem;
  margin: 0 1rem;
  min-height: calc(100vh - 14rem);
  background: var(--white);
}

.wrapper_buttons_select {
  border-left: 1px solid var(--line-table);
  border-right: 1px solid var(--line-table);
  border-top: 1px solid var(--line-table);
  padding: 1rem 1rem;
  border-radius: 1rem 1rem 0 0;
  display: flex;
  width: auto;
  align-items: center;
  justify-content: space-between;

  gap: 1rem;
}

.selectReload__button {
  padding: 0.3rem;
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  background-color: var(--primary);

  svg {
    stroke: var(--white);
    fill: var(--white);
  }
}

.sectionContainer {
  h1 {
    margin: 0 0 2rem 0;
  }
}

.loading {
  cursor: wait;
}

.message {
  width: calc(100% - 7.3rem);
  margin-left: 1rem;
  background-color: var(--purple-400);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  color: var(--white);
  height: 1.1rem;
  position: absolute;
  border-radius: 0 0 1rem 1rem;
}

.error {
  width: calc(100% - 9.3rem);
  margin-left: 1rem;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  color: var(--white);
  height: 1.1rem;
  position: absolute;
  border-radius: 0 0 1rem 1rem;
}

.send {
  @include button_();
  &:hover {
    opacity: 0.9;
    color: var(--white);
  }
}

.upload {
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.buttonActive {
  width: 400px;
  height: 2rem;
  display: flex;
  justify-content: flex-end;
  margin-right: 2rem;

  button {
    background: var(--primary);
    margin-left: 1rem;
    height: 2rem;
    color: var(--white);
  }
}

.buttonDisabled {
  width: 400px;
  height: 2rem;
  display: flex;
  justify-content: flex-end;
  margin-right: 2rem;

  button {
    opacity: 0.4;
    background: var(--purple-50);
    margin-left: 1rem;
    height: 2rem;
    color: var(--white);
    cursor: no-drop;
  }
}

.ContainerButtonPay {
  display: flex;
  width: 20rem;
  justify-content: flex-end;

  button:nth-child(2) {
    margin-right: 0;
  }
}

.buttonPay {
  background: var(--primary);
  height: 2rem;
  color: var(--white);
  padding: 1rem;
  border-radius: 0.5rem;
  margin-left: auto;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8rem;
}

.cardMovements {
  border: 1px solid var(--gray-100);
  margin: 0.5rem 2rem 0.5rem 2rem;
  border-radius: 0.5rem;

  div:nth-child(1) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;

    span:nth-child(3) {
      width: 10rem;
    }

    span {
      width: 6rem;
      text-align: left;
    }
  }

  div:nth-child(2) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--gray-100);
    padding: 1rem;

    span:nth-child(3) {
      width: 10rem;
    }

    span {
      width: 6rem;
      text-align: left;
    }
  }

  svg {
    cursor: pointer;
  }
}

.cardMovementsNew {
  border: 1px solid var(--gray-100);
  margin: 0.5rem 2rem 0.5rem 2rem;
  border-radius: 0.5rem;

  div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1rem;

    span {
      width: 100%;
      font-size: 0.9rem;

      text-align: center;
    }
  }
}

.arowPages {
  margin: 1rem 2rem 1rem 2rem;
  display: flex;
  justify-content: space-between;

  div {
    display: flex;
    margin-right: 1rem;
    align-items: center;
  }

  @media screen and (max-width: 600px) {
    padding: 10px;
    width: 300px;
    height: 100px;
    flex-direction: column;
  }

  svg {
    cursor: pointer;
  }
}

.textPage {
  font-size: 1rem;
  margin-right: 0.5rem;
  color: var(--gray-900);
}

.listLoader {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 420px) {
  .form {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 1rem 0 2rem 0;
  }

  .container {
    width: calc(100% - 2rem);
    margin-left: -4rem;
    height: 100%;
  }

  .upload {
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
    padding: 0.5rem 0.5rem;
    height: 5rem;

    button {
      margin-left: auto;
    }
  }

  .cardMovementsNew {
    border: 1px solid var(--gray-100);
    margin: 0.5rem 0.5rem 0.5rem 0.5rem;
    border-radius: 0.5rem;

    div {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 1rem;

      span {
        width: 50%;
        font-size: 0.9rem;

        text-align: center;
      }
    }
  }

  .cardOrder {
    cursor: pointer;
    height: 4rem;
    margin: 0.5rem 0.5rem 0.5rem 0.5rem;
    border-radius: 0.5rem;

    div {
      box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.15);
      border-radius: 1rem;
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      background-color: var(--white);
      padding: 1rem;

      span {
        width: 4rem;
        text-align: center;
        font-size: 0.6rem;
      }

      span:nth-child(4) {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          width: 4rem;
        }
      }
    }
  }

  .buttonPay {
    background: var(--primary);
    height: 2rem;
    color: var(--white);
    padding: 1rem;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6rem;
  }

  .ContainerButtonPay {
    top: 24rem;
    left: -2rem;
    position: absolute;
    display: flex;
    width: 100vw;
    align-items: center;
    justify-content: center;

    button:nth-child(2) {
      margin-right: 0;
    }
  }

  .message {
    width: 100%;
    margin-left: 1rem;
    background-color: var(--purple-400);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    color: var(--white);
    height: 1.1rem;
    position: absolute;
    border-radius: 0 0 1rem 1rem;
  }

  .error {
    width: 100%;
    margin-left: 1rem;
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    color: var(--white);
    height: 1.1rem;
    position: absolute;
    border-radius: 0 0 1rem 1rem;
  }
}

.arrow {
  display: flex;
  align-items: center;
  gap: 2rem;
  height: 4;
}

.segment {
  padding: 2rem;
  margin: 1rem 0;
  border: 1px solid var(--gray-10);
  height: fit-content;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.buttons__div {
  display: flex;
  gap: 0.5rem;
}

.icon__loader {
  animation: spin 0.75s linear infinite;
}

.bank__slip {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
  .bank__slip__title {
    font-weight: bold;
    font-size: 1.5rem;
  }
}

.value {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  &[data-companyname] {
    width: 100%;
    align-items: baseline;
  }
  &:not([data-companyname]) {
    width: 100%;
    align-items: center;
  }
}

.total__value {
  font-size: 16px;
  font-weight: bold;
}

.bold {
  font-weight: bold;
}

.width__100 {
  width: 100%;
}

.dark__green {
  color: darkgreen;
}

.dark__red {
  color: darkred;
}

.finish__message {
  font-size: 22px;
  text-align: center;
  .finish__message__link {
    color: var(--primary-700);
    &:hover {
      color: var(--primary);
    }
  }
}

.finish__message__div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  margin-top: 2em;
}
.wrapper_cards_resume {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: auto;
  padding: 1rem;
}
