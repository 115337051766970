.proofContainer {
  background: var(--white);
  padding: 3rem;
  height: 100%;
  min-height: 80vh;
  margin: 1rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--gray-100);
}

.proofLoading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.proof {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contentProof {
  width: 500px;
  height: fit-content;
  border: 1px solid var(--gray-100);
  border-radius: 1rem;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 1rem;

  hr {
    width: 100%;
    border-top: 1px dashed var(--gray-100);
    margin: 0;
  }

  h1 {
    font-size: 2rem;
    font-weight: bold;
    color: var(--black-800);
    margin: 0;

    ::after {
      width: 100%;
      border-bottom: 1px dashed var(--gray-100);
      content: '';
      margin-top: 0.4rem;
    }
  }

  h2 {
    font-size: 1.6rem;
    font-weight: bold;
    color: var(--black-800);
    margin: 0;
  }

  p.fontSmall {
    font-size: smaller;
  }

  ul:first-of-type {
    .multiline {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
    }
  }

  ul {
    width: 100%;
    list-style: none;
    padding: 0.2rem auto;

    div {
      display: flex;
      align-items: center;
      gap: 0.8rem;

      h3 {
        font-size: large;
        font-weight: bold;
        color: var(--black-800);
      }
    }

    li {
      display: flex;
      justify-content: space-between;
      margin: 0.8rem auto;
      text-align: justify;

      p {
        font-size: 1rem;
      }

      span {
        font-weight: bold;
        font-size: 1rem;
      }
    }
  }
}
