.cardOrder {
  height: 2rem;
  background-color: var(--white);
  display: grid;
  grid-template-columns: 12rem 1fr 9rem 9.375rem 9.375rem 12rem 4rem;
  width: auto;
}
.icon {
  width: 2.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
@mixin circle($background, $color) {
  p {
    background: $background;
    width: auto;
    color: $color;
    padding: 0.5rem 0.5rem;
    border-radius: 8px;
  }
}
@mixin button-copy($background, $color) {
  button {
    background: $background;
    width: auto;
    color: $color;
    padding: 0.5rem 0.5rem;
    border-radius: 8px;
    cursor: pointer;
  }
}
.font {
  font-size: 0.8rem;
  color: var(--gray-600);
  font-weight: 400;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.error {
  @extend .font;
  @extend .center;
  @include circle(var(--red-500), var(--white));
}

.file {
  @extend .font;
  display: flex;
  text-align: center;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.name {
  @extend .font;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.status {
  @extend .font;

  display: flex;
  align-items: center;
  justify-content: center;
}

.key {
  @extend .font;

  text-align: right;
  display: flex;
  align-items: center;
  justify-content: center;
}
.description {
  @extend .font;

  text-align: right;
  display: flex;
  align-items: center;
  justify-content: center;
}
.date {
  @extend .font;

  text-align: right;
  display: flex;
  align-items: center;
  justify-content: center;
}
.value {
  @extend .date;
}
