@import '../../styles/_global';

.container {
  @extend .container_style;
  display: flex;
  flex-direction: column;
}
.header_style_ {
  @extend .header_style;
  padding: 0 1rem;
}
.form {
  display: flex;
  height: 4rem;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  gap: 1rem;
  background-color: var(--white);
}

@media (max-width: 420px) {
  .container {
    width: 100vw;
    margin-left: -2rem;
  }
}

.buttons {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.box_cards {
  display: flex;
  justify-content: space-between;
}

.box_cards_list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}
.wrapper_content {
  display: flex;
  flex-direction: column;
  margin: 1rem 1.5rem;
}
.selectReload {
  border-left: 1px solid var(--line-table);
  border-right: 1px solid var(--line-table);
  border-top: 1px solid var(--line-table);
  padding: 1rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 1rem 1rem 0 0;
}
.wrapper_table {
  padding: 0;
}
.wrapper_buttons_select {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: auto;
  button {
    margin: 0 1rem;
  }
}
.wrapper_acions {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-left: auto;
}
.selectReload__button {
  padding: 0.3rem;
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  background-color: var(--primary);

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  svg {
    stroke: var(--white);
    fill: var(--white);
  }
}

.content_list {
  background-color: var(--white);
  border-radius: 1rem;
  margin: 0 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.title_list {
  height: 4rem;
  display: flex;
  margin-top: 1rem;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5rem;
  gap: 1rem;
  header {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 40rem;
    h3 {
      font-size: 1.5rem !important;
      font-weight: 500;
      color: var(--gray-900) !important;
    }
    p {
      background: var(--gray-10);
      font-weight: 500;
      height: 2rem;
      width: auto;
      padding: 0 0.8rem;
      border-radius: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.message {
  width: calc(100% - 7.3rem);
  margin-left: 1rem;
  background-color: var(--purple-400);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  color: var(--white);
  height: 1.1rem;
  position: absolute;
  border-radius: 0 0 1rem 1rem;
}

.error {
  width: calc(100% - 9.3rem);
  margin-left: 1rem;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  color: var(--white);
  height: 1.1rem;
  position: absolute;
  border-radius: 0 0 1rem 1rem;
}

.send {
  @include button_();
  &:hover {
    opacity: 0.9;
    color: var(--white);
  }
}
.send_approve {
  @include button_();
  font-size: 0.9rem;
  border-radius: 1rem;
  &:hover {
    opacity: 0.9;
    color: var(--white);
  }
}
.send_ {
  border: 1px solid var(--gray-300);
  padding: 1rem;
  color: var(--gray-400);
  background: transparent;
  border-radius: 0.5rem;
  width: auto;
  height: 3rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  transition: 0.3s ease-in;
  &:hover {
    color: var(--gray-500);
  }
}
.separator {
  border: 0.0625rem solid var(--gray-300);
  border-radius: 0.0625rem;
  display: flex;
  height: 2.5rem;
}
.upload {
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.buttonActive {
  width: 400px;
  height: 2rem;
  display: flex;
  justify-content: flex-end;
  margin-right: 2rem;

  button {
    background: var(--primary);
    margin-left: 1rem;
    height: 2rem;
    color: var(--white);
  }
}

.buttonDisabled {
  width: 400px;
  height: 2rem;
  display: flex;
  justify-content: flex-end;
  margin-right: 2rem;

  button {
    opacity: 0.4;
    background: var(--purple-50);
    margin-left: 1rem;
    height: 2rem;
    color: var(--white);
    cursor: no-drop;
  }
}

.ContainerButtonPay {
  display: flex;
  width: 20rem;
  justify-content: flex-end;

  button:nth-child(2) {
    margin-right: 0;
  }
}

.buttonPay {
  background: var(--primary);
  height: 2rem;
  color: var(--white);
  padding: 1rem;
  border-radius: 0.5rem;
  margin-left: auto;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8rem;
}

.cardMovements {
  border: 1px solid var(--gray-100);
  margin: 0.5rem 2rem 0.5rem 2rem;
  border-radius: 0.5rem;

  div:nth-child(1) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;

    span:nth-child(3) {
      width: 10rem;
    }

    span {
      width: 6rem;
      text-align: left;
    }
  }

  div:nth-child(2) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--gray-100);
    padding: 1rem;

    span:nth-child(3) {
      width: 10rem;
    }

    span {
      width: 6rem;
      text-align: left;
    }
  }

  svg {
    cursor: pointer;
  }
}

.cardMovementsNew {
  border: 1px solid var(--gray-100);
  margin: 0.5rem 2rem 0.5rem 2rem;
  border-radius: 0.5rem;

  div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1rem;

    span {
      width: 100%;
      font-size: 0.9rem;

      text-align: center;
    }
  }
}

.arowPages {
  margin: 1rem 2rem 1rem 2rem;
  display: flex;
  justify-content: space-between;

  div {
    display: flex;
    margin-right: 1rem;
    align-items: center;
  }

  @media screen and (max-width: 600px) {
    padding: 10px;
    width: 300px;
    height: 100px;
    flex-direction: column;
  }

  svg {
    cursor: pointer;
  }
}

.textPage {
  font-size: 1rem;
  margin-right: 0.5rem;
  color: var(--gray-900);
}

.listLoader {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 420px) {
  .form {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 1rem 0 2rem 0;
  }

  .selectReload {
    margin: 1rem 0 2rem 0;
  }

  .container {
    width: calc(100% - 2rem);
    margin-left: -4rem;
    height: 100%;
  }

  .upload {
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
    padding: 0.5rem 0.5rem;
    height: 5rem;

    button {
      margin-left: auto;
    }
  }

  .cardMovementsNew {
    border: 1px solid var(--gray-100);
    margin: 0.5rem 0.5rem 0.5rem 0.5rem;
    border-radius: 0.5rem;

    div {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 1rem;

      span {
        width: 50%;
        font-size: 0.9rem;

        text-align: center;
      }
    }
  }

  .cardOrder {
    cursor: pointer;
    height: 4rem;
    margin: 0.5rem 0.5rem 0.5rem 0.5rem;
    border-radius: 0.5rem;

    div {
      box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.15);
      border-radius: 1rem;
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      background-color: var(--white);
      padding: 1rem;

      span {
        width: 4rem;
        text-align: center;
        font-size: 0.6rem;
      }

      span:nth-child(4) {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          width: 4rem;
        }
      }
    }
  }

  .buttonPay {
    background: var(--primary);
    height: 2rem;
    color: var(--white);
    padding: 1rem;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6rem;
  }

  .ContainerButtonPay {
    top: 24rem;
    left: -2rem;
    position: absolute;
    display: flex;
    width: 100vw;
    align-items: center;
    justify-content: center;

    button:nth-child(2) {
      margin-right: 0;
    }
  }

  .message {
    width: 100%;
    margin-left: 1rem;
    background-color: var(--purple-400);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    color: var(--white);
    height: 1.1rem;
    position: absolute;
    border-radius: 0 0 1rem 1rem;
  }

  .error {
    width: 100%;
    margin-left: 1rem;
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    color: var(--white);
    height: 1.1rem;
    position: absolute;
    border-radius: 0 0 1rem 1rem;
  }
}

.arrow {
  padding-left: 1.5rem;
  display: flex;
  align-items: center;
  gap: 2rem;
  height: 4;
}
.ranger {
  width: max-content;
  background: var(--white);
  margin: 0 1rem;
  display: flex;
  align-items: center;
  border: 1px solid var(--gray-50);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  gap: 0.8rem;

  svg {
    width: 1.4rem;
  }
  span {
    font-size: 0.875rem;
    color: var(--gray-500);
  }
}
.container_arrow_cards {
  display: block;
}
.wrapper_cards_resume {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: auto;
  padding: 1rem;
}
