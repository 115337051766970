.notification_list {
  position: fixed;
  right: 1rem;
  z-index: 9999999;
  top: 5rem;
}
.notification {
  width: 25rem;
  height: auto;
  font-size: 0.8rem;
  font-weight: 600;
  color: var(--gray-400);
  padding: 1.25rem 1.25rem 1.25rem 1.25rem;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container {
  display: flex;
  gap: 1rem;
}
.content {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  h3 {
    font-size: 1rem;
    font-weight: 600;

    text-align: start;
    line-height: 0.9rem;
    height: auto;
  }
  p {
    text-align: start;
    line-height: 0.9rem;
    height: auto;
    font-weight: 400;
    strong {
      font-weight: 800;
    }
    h1 {
      font-weight: 600 !important;
      font-size: 0.8rem !important;
      font-weight: bold !important;
      text-transform: uppercase !important;
    }
  }
}

.notificationType0 {
  background-color: #f6fff9;
  color: #4e585c;
  border-radius: 0.5rem;
  border: 1px solid #9dc0ee;
}

.notificationType1 {
  background-color: #fff8ec;
  color: #4e585c;
  border-radius: 0.5rem;
  border: 1px solid #f7d9a4;
}

.notificationType2 {
  background-color: #fff5f3;
  color: #4e585c;
  border-radius: 0.5rem;
  border: 1px solid #f4b0a1;
}
.notificationType3 {
  background-color: #f6fff9;
  color: #4e585c;
  border-radius: 0.5rem;
  border: 1px solid #48c1b5;
}

.icon {
  width: 30px;
}
.icon_close {
  width: 30px;

  svg {
    cursor: pointer;
  }
}

.animeLeft {
  opacity: 0;
  transform: translateX(50px);
  animation: animeLeft 0.9s forwards;
}

@keyframes animeLeft {
  to {
    opacity: 1;
    transform: initial;
  }
}

.progress {
  position: absolute;
  bottom: 0.2rem;
  left: 0.5rem;
  height: 3px;
  border-radius: 3px;
  background-color: #9dc0ee;
  width: 24rem;
  opacity: 1;
  animation: identifier 5s linear forwards;
  @keyframes identifier {
    0% {
      width: 24rem;
      opacity: 0.6;
    }
    25% {
      width: 16rem;
      opacity: 0.5;
    }
    50% {
      width: 8rem;
      opacity: 0.4;
    }
    100% {
      width: 0rem;
      opacity: 0.2;
    }
  }
}

.container_progress {
  display: flex;
  justify-content: space-between;
}
