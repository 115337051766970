// .wrapper_pages {
//   width: 7rem;
//   display: flex;
//   align-items: center;
//   justify-content: flex-end;
//   font-size: 0.8rem;
//   font-weight: 600;

//   .center {
//     background: var(--gray-10);
//     padding: 0.5rem 0.5rem;
//     border-radius: 0.5rem;
//     margin: 0 0.25rem;
//     display: flex;
//     align-items: center;
//     svg {
//       cursor: no-drop;
//     }
//   }
//   .pointer {
//     width: 3rem;
//     padding-right: 0.2rem;
//     display: flex;
//     align-items: center;
//     color: var(--white);
//     svg {
//       cursor: no-drop;
//     }
//   }
//   .pointer_p {
//     width: 3rem;
//     padding-right: 0.2rem;
//     display: flex;
//     align-items: center;
//     color: var(--gray-600);
//     svg {
//       cursor: pointer;
//     }
//   }
//   .pointer_ {
//     width: 3rem;
//     padding-left: 0.2rem;
//     display: flex;
//     align-items: center;
//     color: var(--gray-600);
//     svg {
//       cursor: pointer;
//     }
//   }
//   .pointer__ {
//     width: 3rem;
//     padding-left: 0.2rem;
//     display: flex;
//     align-items: center;
//     color: var(--white);
//     svg {
//       cursor: no-drop;
//     }
//   }
// }
.wrapper_pages {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--gray-100);
  border-radius: 0.5rem;
}

.pointer {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--gray-500);
  padding: 0 1rem;
  height: 100%;

  &:disabled {
    color: var(--gray-50);
    cursor: not-allowed;
  }
}

.next {
  background: transparent;
  width: max-content;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  height: 100%;
  font-size: 0.9rem;
  p {
    color: var(--gray-500);
    font-weight: 600;
  }
  &:disabled {
    color: var(--gray-50);

    p {
      cursor: not-allowed;
      color: var(--gray-50);
      font-weight: 600;
    }
  }
}

.back {
  width: max-content;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--gray-500);
  padding-right: 1rem;
  height: 2rem;
  justify-content: space-between;
  border-right: 1px solid var(--gray-100);
  gap: 1rem;
  font-size: 0.9rem;

  p {
    font-weight: 600;
  }
  &:disabled {
    color: var(--gray-50);

    p {
      cursor: not-allowed;
      color: var(--gray-50);
      font-weight: 600;
    }
  }
}

.page_number {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  width: 2.5rem;
  font-size: 0.9rem;
  color: var(--gray-500);
  border-right: 1px solid var(--gray-100);
  &.active {
    font-weight: 800;
    border-bottom: 2px solid var(--primary-color);
  }
}

.ellipsis {
  width: 3rem;
  text-align: center;
  padding: 0.5rem;
  color: var(--gray-200);
  border-right: 1px solid var(--gray-100);
}
