.container {
  width: auto;
  min-width: 12rem;
  height: 8rem;
  padding: 0.7rem;
  background: var(--white);
  border-radius: 1rem;
  border: 1px solid var(--gray-50);
}
.container_ {
  width: auto;
  min-width: 12rem;
  height: 8rem;
  padding: 0.7rem;
  background: var(--white);
  border-radius: 1rem;
  border: 1px solid var(--gray-400);
}
.box {
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  h4 {
    display: flex;
    width: 100%;
    justify-content: space-between;
    span {
      color: var(--var-600);
      font-weight: 600;
      font-size: 0.9rem;
    }
    div {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      strong {
        color: var(--var-600);
        font-weight: 600;
        font-size: 0.9rem;
      }
      span {
        color: var(--var-400);
        font-weight: 400;
        font-size: 0.8rem;

        margin-bottom: 0;
      }
    }
  }
  h1 {
    color: var(--var-600);
    font-weight: 900;
    font-size: 1.5rem;

    padding-bottom: 0;
  }
  h5 {
    color: var(--var-600);
    font-weight: 400;
    font-size: 0.8rem;
  }
}
.wrapper_button {
  display: flex;
  gap: 0.3rem;
}
.button_styles {
  padding: 0.4rem 0.3rem;
  font-size: 0.7rem;
  border-radius: 0.5rem;
  font-weight: 600;
}
.details {
  @extend .button_styles;
  background: var(--white);
  border: 1px solid var(--gray-700);
  color: var(--primary);
  cursor: pointer;
  transition: 0.3s ease-in;
  &:hover {
    background-color: var(--primary-700);
    color: var(--white);
  }
}

.send {
  @extend .button_styles;
  color: var(--gray-50);
  background: var(--primary);
  cursor: pointer;

  transition: 0.3s ease-in;
  &:hover {
    background-color: var(--primary-700);
  }
}
