.container {
  padding: 1rem;
  margin: 1rem 0 1rem 0;
  height: auto;
  border-radius: 0.5rem;
}

.title {
  font-size: 1rem;
  strong {
    font-weight: 600;
  }
}
.form {
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
}

.form_wrapper {
  display: grid;
  grid-template-columns: 1fr 14rem 10rem 10rem;
  gap: 1rem;
}
.form_wrapper_ {
  display: grid;
  grid-template-columns: 2.3fr 1fr;
  gap: 1rem;
}
.form_wrapper_account {
  display: grid;
  padding-bottom: 1rem;
  grid-template-columns: 1fr 6rem 1fr 6rem;
  gap: 1rem;
}
.form_wrapper__ {
  display: grid;
  padding-bottom: 1rem;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

//butons


.buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
}
.buttons_ {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  padding-right: 4rem;
}
.buttons_finish {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}
.buttons_form {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}
.button {
  height: 3rem;
  padding: 1rem 3rem;
  border-radius: 0.5rem;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  background: var(--primary-700);
  color: var(--white);

  &:disabled {
    opacity: 0.5;
  }
}

.preview {
  font-size: 0.8rem;
  font-weight: 600;
}

.button_cancel {
  height: 3rem;

  padding: 0.3rem 1rem;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  background: transparent;
  color: var(--primary-700);
  border-bottom: 0.125rem solid var(--primary-700);
  &:disabled {
    opacity: 0.5;
  }
}

.preview {
  font-size: 0.9rem;
  font-weight: 600;
}

.button_proof {
  @extend .button;
  background: var(--white);
  border: 0.125rem solid var(--primary-700);
  color: var(--primary-700);
}

.select {
  height: 2rem;
  min-width: 12rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 1rem;
  margin-top: 1.2rem;
  border-radius: 0.5rem;
  border: 1px solid var(--gray-100);
  position: relative;
  select {
    all: initial;
    width: 100%;
    z-index: 2;
    font-family: 'Roboto', sans-serif;
    font-size: 0.9rem;
    color: var(--gray-200);
  }
  svg {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
}
.select_ {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 1.5rem;
}

.description_payment {
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
}

.description {
  h2 {
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--gray-600);
  }

  p {
    font-size: 0.9rem;
    color: var(--gray-400);
    margin-right: 1rem;
  }
  span {
    margin-top: 0.3rem;
    display: flex;
  }
}
.description_ {
  @extend .description;

  span:not(:last-child) {
    color: white;
  }
}

.error__input {
  color: var(--red-500);
  font-size: 0.7rem;
  margin-top: 0.5rem;

  @media screen and (max-width: 767px) {
    margin-top: 0.5rem;
  }
}
/*values */
.block {
  border: 0.0625rem solid var(--gray-50);
  border-radius: 0.1rem;
  margin: 0.3rem 0;
}

/* values */
.values_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 4rem;
}
.values {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.line {
  border: 0.0625rem solid var(--gray-50);
  border-radius: 0.1rem;
  margin: 0.3rem 0;
}

.values_ {
  display: flex;
  gap: 1rem;
  justify-content: space-between;

  span {
    margin-top: 0.5rem;
    font-size: 1rem;
    margin-right: 0;
    color: var(--gray-400);
  }
}

/* info */
.header_info_wrapper {
  display: flex;
  gap: 1rem;
  padding: 1rem 0;
  width: 100%;
  min-width: 20rem;
}
.header_info {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 0;
  width: 100%;
  min-width: 20rem;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.title_info {
  font-size: 0.75rem;
  font-weight: 300;
}

.title_description {
  font-weight: 600;
  font-size: 1rem;
}

.line_info {
  margin: 0;
  min-width: 20rem;
}

.box_info {
  min-width: 30rem;
  background: var(--gray-10);
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 0.5rem;

  span {
    display: flex;
    gap: 0.5rem;
    font-size: 0.75rem;
    font-weight: 600;

    p {
      font-weight: 300;
      font-size: 0.75rem;
    }
  }
}

.fee {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  span {
    font-size: 0.75rem;
    font-weight: 300;
    margin: 0;
  }
  p {
    font-size: 0.8rem;
    font-weight: bold;
    margin: 0;
  }
}
.title_amount {
  font-size: 1rem;
  font-weight: 400;
}
.amount {
  font-size: 2rem;
  font-weight: 600;
}

.description_finish {
  display: flex;
  padding: 3rem 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;

  h2 {
    font-weight: 600;
    font-size: 32px;
    color: var(--gray-800);
  }
  p {
    text-align: center;
    line-height: 1.5rem;
    color: var(--gray-300);
  }
}
