.modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  top: 0px;
  left: 0px;
  display: flex;
  z-index: 100;
  display: flex;
  justify-content: center;

  align-items: center;
}

.modalBox {
  height: auto;
  width: 45rem;
  background: var(--white);
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  padding: 1rem;

  button {
    height: 2.5rem;
    width: 6rem;
    margin: 0.2rem;
    background-color: var(--primary);
    color: var(--white);
    border-radius: 0.2rem;
    font-weight: bold;
    &:hover {
      opacity: 0.8;
    }
  }
}

.title {
  margin-top: 0.6rem;
  font-weight: bold;
}

.name {
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  text-align: start;
  font-weight: bold;
}

.text {
  margin-top: 1.4rem;
  margin-bottom: 1rem;
  line-height: 1.4rem;
  border: 1px solid var(--gray-100);
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.wrapper_button {
  margin-top: auto;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 2rem;
}

.description {
  margin-bottom: 1rem;
  line-height: 1.4rem;
}
@media (max-width: 420px) {
  .modal {
    padding: 2rem calc(2rem + 15px) 2rem 2rem;
  }
  .text {
    margin-top: 1rem;
    margin-bottom: 1rem;
    line-height: 1.4rem;
  }

  .modalBox {
    height: 35rem;
    font-size: 0.8rem;
  }
}
