.loading {
  margin-left: 20%;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    width: 1.2rem;
    height: 1.2rem;
    background: transparent;
    border: 2px solid var(--gray-100);
    border-top-color: var(--primary);
    border-radius: 50%;
    animation: isRotation 0.9s ease infinite;
  }

  @keyframes isRotation {
    to {
      transform: rotate(1turn);
    }
  }
}
