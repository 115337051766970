/* AddUserForm.module.scss */

.form-container {
  width: 100%;
  border-radius: 0.5rem;
  margin: 1.25rem 0;
  display: flex;
  font-size: 1rem;
  flex-direction: column;
  padding: 1.5rem 1.5rem;
  background: var(--gray-10);
}

.button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  border: 1px solid var(--primary-700);
  padding: 0.8rem 1.5rem;
  border-radius: 0.5rem;
  font-size: 0.8rem;
  cursor: pointer;
}

.button_canceled {
  @extend .button;
  color: var(--red-500);
  background: var(--white);
  border: 1px solid var(--red-500);

  transition: 0.3s ease-in;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    filter: brightness(0.9);
  }
}

.button_save {
  @extend .button;
  background-color: var(--primary-700);
  color: var(--white);
  transition: 0.3s ease-in;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    filter: brightness(0.9);
  }
}

.wrapper_button {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.wrapper_form {
  display: flex;
  gap: 1rem;
  width: 100%;
}

.wrapper_form_ {
  display: flex;
  gap: 1rem;
  width: 100%;
}
.block_form {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.block_check {
  display: flex;
  flex-direction: column;
  width: 50%;
}

//select

.select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 1rem;
  border-radius: 0.5rem;
  border: 1px solid var(--primary-700);
  position: relative;
  select {
    all: initial;
    width: 100%;
    z-index: 2;
    font-family: 'Roboto', sans-serif;
    color: var(--gray-600);
  }
  svg {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
  margin-top: 1rem;
}
.select_type {
  max-width: 20rem;
}
.select_type_ {
  display: none;
}
//radio

.radio_type {
  padding-bottom: 1rem;
  width: 22rem;
  display: flex;
  flex-direction: column;
}

.label {
  margin-top: 1rem;
  color: var(--gray-600);
  font-size: 0.9rem;
}
.wrapper_radio {
  display: flex;
  gap: 2rem;
  align-items: center;
  p {
    margin-top: 0;
    [type='radio']:checked,
    [type='radio']:not(:checked) {
      position: absolute;
      left: -9999px;
    }
    [type='radio']:checked + label,
    [type='radio']:not(:checked) + label {
      position: relative;
      padding-left: 1.75rem;
      cursor: pointer;
      line-height: 1.25rem;
      display: inline-block;
      color: #666;
    }
    [type='radio']:checked + label:before,
    [type='radio']:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 1.125rem;
      height: 1.125rem;
      border: 1px solid #ddd;
      border-radius: 100%;
      background: #fff;
    }
    [type='radio']:checked + label:after,
    [type='radio']:not(:checked) + label:after {
      content: '';
      width: 0.75rem;
      height: 0.75rem;
      background: var(--primary-700);
      position: absolute;
      top: 0.18rem;
      margin: 0 auto;
      left: 0.18rem;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }
    [type='radio']:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    [type='radio']:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
}
//checkbox

// Variables
$checkmark-width-left: 15px;
$checkmark-width-right: 30px;
$checkmark-position-top: 32px;
$checkmark-position-left: 21px;
$animation-speed: 0.3s;
// Wrapper
.check-wrap-box {
  position: absolute;
  top: -40px;
  right: -40px;
  z-index: 3;
}
.check-wrap {
  width: 4.6875rem;
  height: 4.6875rem;
  border-radius: 50%;
  border: 2px solid var(--green);
  position: relative;
  overflow: hidden;
  animation: wrap $animation-speed ease-in-out forwards;
  animation-delay: 0.3s;
  transform: scale(0);

  // Checkmark
  &::before,
  &::after {
    content: '';
    position: absolute;
    background-color: var(--white);
    width: 0;
    height: 5px;
    transform-origin: left;
    animation-duration: $animation-speed;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }

  &::before {
    top: $checkmark-position-top;
    left: $checkmark-position-left;
    transform: rotate(45deg);
    animation-name: left;
    animation-delay: 0.8s;
  }

  &::after {
    top: $checkmark-position-top + 10px;
    left: $checkmark-position-left + 8px;
    transform: rotate(-45deg);
    animation-name: right;
    animation-delay: 1.1s;
  }
}

// Animation wrapper
@keyframes wrap {
  0% {
    background-color: transparent;
    transform: scale(0);
  }
  100% {
    background-color: var(--green);
    transform: scale(0.4);
  }
}

// Checkmark keyframe mixin
@mixin checkmark($name, $width) {
  @keyframes #{$name} {
    0% {
      width: 0;
    }
    100% {
      width: $width;
    }
  }
}

// Checkmark keyframes
@include checkmark(left, $checkmark-width-left);
@include checkmark(right, $checkmark-width-right);
