.button {
  min-width: fit-content;
  padding: 1rem 1rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  background: var(--primary-700);
  font-weight: bold;
  color: var(--white);
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.container {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
