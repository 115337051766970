.formCard {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
}

.header {
  height: auto;

  h2 {
    font-size: 1.3rem;
    font-weight: 600;
    color: var(--gray-600);
  }

  p {
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.2em;
    color: var(--gray-400);
  }
}
