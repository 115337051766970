.message {
  position: fixed;
  top: 0;
  right: 0;
  margin-top: 5rem;
  z-index: 999;
  height: 4rem;
  width: 30em;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  border-radius: 8px 0 0 8px;
  color: var(--white);
  font-weight: bold;
}
.animeLeft {
  opacity: 0;
  transform: translatex(50px);
  animation: animeLeft 0.9s forwards;
}

@keyframes animeLeft {
  to {
    opacity: 1;
    transform: initial;
  }
}

@media screen and (max-width: 420px) {
  .message {
    width: 20rem;
    font-size: 1rem;
  }
}

.bg {
  background: var(--primary);
}

.bg_ {
  background: var(--primary-700);
}
