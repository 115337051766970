.container {
  width: calc(100% - 2rem);
  height: calc(100vh - 1rem);
  margin: 1rem;
  background: #f9f9f9;
  border-radius: 8px;
  position: relative;
  padding: 0 2rem;
}

.back {
  height: 2rem;
  padding: 2rem 1rem;
}
.card_box {
  cursor: pointer;
  position: relative;
  border: 2px solid var(--primary);
  width: 5rem;
  height: 5rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  h3 {
    font-size: 0.625rem;
    font-weight: 600;
  }
  button {
    background: transparent;
  }

  transition: 0.3s ease-in;
  // &:hover {
  //   border: 2px dashed var(--primary-700);
  //   background: var(--gray-50);
  //   h3 {
  //     color: var(--primary-700);
  //   }

  //   button {
  //     svg {
  //       stroke: var(--primary-700);
  //       fill: var(--primary-700);
  //       color: var(--primary-700);
  //     }
  //   }
  // }
}

.card_copy {
  position: absolute;
  top: 0px;
  left: -20px;
  z-index: 2;

  button {
    padding: 0.4rem;
    border-radius: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed var(--gray-400);
    background: var(--white);
  }
}

.cards_box {
  display: flex;
  height: 12rem;
  justify-content: flex-start;
  align-items: center;
  gap: 4rem;
  padding: 0 1rem;
}

.box {
  width: 100%;
  margin: 0 auto;
  padding: 1rem 1rem;
  display: grid;
  grid-template-areas:
    'header header header'
    'sidebar content content';
  grid-template-rows: 5rem auto;
  grid-template-columns: 1fr 2fr;
  grid-gap: 10px;
  height: 36rem;
  animation: blurSet 1s ease-in-out normal;

  @keyframes blurSet {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
.card {
  background: var(--white);
  border-radius: 1rem;
  box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.15);
}
.header {
  grid-area: header;
  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 2.125rem;
    line-height: 2.875rem;

    color: var(--gray-600);
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.875rem;

    color: var(--gray-600);
  }
}
.list {
  @extend .card;

  grid-area: sidebar;

  display: grid;
  grid-template-columns: 30px 1fr;
  padding: 2rem;
}
.list_ranger {
  gap: 0.2rem;
  display: flex;
  flex-direction: column;
  margin-top: 0.15rem;
}
.list_ranger_text {
  gap: 0.2rem;
  display: flex;
  flex-direction: column;
}
.ball {
  height: auto;
  max-height: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.2rem;
  div {
    height: 1rem;
    width: 1rem;
    border: 6px solid var(--gray-100);
    background: var(--white);
    border-radius: 0.5rem;
  }
  p {
    height: 3.4rem;
    width: 2px;
    border-radius: 0.5rem;
    background: var(--gray-100);
  }
}
.ball_ {
  height: auto;
  max-height: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.2rem;
  div {
    height: 1rem;
    width: 1rem;
    border: 6px solid var(--primary);
    background: var(--white);
    border-radius: 0.5rem;
  }
  p {
    height: 3.4rem;
    width: 2px;
    border-radius: 0.5rem;
    background: var(--primary);
  }
}
.text {
  height: 4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.2rem;

  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: var(--gray-600);
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: var(--gray-400);
  }
}
.card_links {
  width: 100%;
  padding: 2rem 1rem 0 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  animation: blurSet 1s ease-in-out normal;

  @keyframes blurSet {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  header {
    @extend .header_step;
  }
}
.box_title {
  color: var(--gray-800);
  font-weight: bold;
  font-size: 32px;
  padding: 0 0 4rem 0;
}

.content {
  @extend .card;

  grid-area: content;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.header_step {
  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.375rem;
    color: var(--gray-600);
    padding: 1rem 0;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.4rem;
    color: var(--gray-600);
  }
}
.step_one {
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  header {
    @extend .header_step;
  }
}
.step_two {
  width: 100%;
  padding: 2rem 1rem 0 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  animation: blurSet 1s ease-in-out normal;

  @keyframes blurSet {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  header {
    @extend .header_step;
  }
}
.step_three {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 100%;
  padding: 2rem 0;

  header {
    @extend .header_step;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      text-align: center;
      padding: 0 8rem;
      line-height: 1.4rem;
    }
  }
  animation: blurSet 1s ease-in-out normal;

  @keyframes blurSet {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
.search_user {
  display: flex;
  flex-direction: column;

  gap: 1rem;
  width: 100%;
}

.wrapper_button {
  padding: 2rem 2rem;
  height: 25rem;
  display: flex;
  margin-left: auto;
  gap: 2rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  animation: blurSet 1s ease-in-out normal;

  @keyframes blurSet {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
.wrapper_button_add {
  height: 6rem;
  padding: 0 4rem;
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: auto;
  gap: 2rem;
  margin-top: auto;
  justify-content: flex-start;

  animation: blurSet 1s ease-in-out normal;

  @keyframes blurSet {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  form {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}

.wrapper_button_navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6rem;
  button {
    color: var(--gray-850);
    padding: 1rem 1.5rem;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: 0.3s ease-in;
    background: var(--white);
    border: 2px solid var(--primary);
    &:hover {
      transform: scale(1.1);
    }
  }
}
.button {
  background: var(--primary);
  color: var(--white);
  font-size: 16px;
  padding: 0.5rem 1rem;
  transform: translateY(0.2rem);
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  width: 8rem;
  &:disabled {
    opacity: 0.6px;
  }
  @media screen and (max-width: 767px) {
    width: auto;
  }
}
.button_add {
  background: var(--primary);
  color: var(--white);
  font-size: 16px;
  padding: 0.8rem 1rem;
  transform: translateY(0.2rem);
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  &:disabled {
    opacity: 0.6px;
  }
  @media screen and (max-width: 767px) {
    width: auto;
  }

  transition: 0.3s ease-in;
  &:hover {
    background-color: var(--primary-700);
  }
}

.button_finally {
  @extend .button_add;
  margin-left: auto;
}

//check

// Variables
$checkmark-width-left: 15px;
$checkmark-width-right: 30px;
$checkmark-position-top: 32px;
$checkmark-position-left: 21px;
$animation-speed: 0.3s;
// Wrapper
.check-wrap-box {
  position: absolute;
  top: -40px;
  right: -40px;
  z-index: 3;
}
.check-wrap {
  width: 4.6875rem;
  height: 4.6875rem;
  border-radius: 50%;
  border: 2px solid var(--green);
  position: relative;
  overflow: hidden;
  animation: wrap $animation-speed ease-in-out forwards;
  animation-delay: 0.3s;
  transform: scale(0);

  // Checkmark
  &::before,
  &::after {
    content: '';
    position: absolute;
    background-color: var(--white);
    width: 0;
    height: 5px;
    transform-origin: left;
    animation-duration: $animation-speed;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }

  &::before {
    top: $checkmark-position-top;
    left: $checkmark-position-left;
    transform: rotate(45deg);
    animation-name: left;
    animation-delay: 0.8s;
  }

  &::after {
    top: $checkmark-position-top + 10px;
    left: $checkmark-position-left + 8px;
    transform: rotate(-45deg);
    animation-name: right;
    animation-delay: 1.1s;
  }
}

// Animation wrapper
@keyframes wrap {
  0% {
    background-color: transparent;
    transform: scale(0);
  }
  100% {
    background-color: var(--green);
    transform: scale(0.4);
  }
}

// Checkmark keyframe mixin
@mixin checkmark($name, $width) {
  @keyframes #{$name} {
    0% {
      width: 0;
    }
    100% {
      width: $width;
    }
  }
}

// Checkmark keyframes
@include checkmark(left, $checkmark-width-left);
@include checkmark(right, $checkmark-width-right);
