.share {
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 100%;
  background-color: var(--white);
  border: 1px solid var(--gray-200);
  color: var(--black-800);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
