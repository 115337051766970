.container {
  background: var(--white);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid var(--gray-100);
  width: 7.2rem;
  height: 2.5rem;
  font-size: 0.85rem;
  padding: 1.5rem 1rem;
  justify-content: space-between;
  border-radius: 1rem;
  color: var(--gray-400);
  cursor: pointer;
  position: relative;
}
.menu {
  position: absolute;
  top: 2.5rem;
  right: 0;
  width: 16rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
  z-index: 10;
  background: var(--white);
  box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.15);
}

.button__plus {
  background: var(--gray-5);
  padding: 0.8rem 0.5rem;
  display: flex;
  font-size: 0.9rem;
  color: var(--gray-700);
  align-items: center;
  gap: 1rem;
  border-radius: 0.5rem;
  transition: ease-in-out 0.2s;
  &:hover {
    background: var(--gray-50);
  }
}
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9;
  background: transparent;
  cursor: auto;
}
