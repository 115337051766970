.font {
  font-size: 0.8rem;
}

.flexCard {
  position: fixed;
  height: 100vh;
  width: 40%;
  top: 0;
  z-index: 9999;
  right: 0;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  padding: 0 1.5rem 1rem 1.5rem;

  opacity: 0;
  transform: translateX(-50px);
  animation: animeLeft 0.5s forwards;
  &::after {
    position: relative;
    content: '';
  }
  &::before {
    content: '';
    width: 100vw;
    height: 100vh;
    position: absolute;
    overflow: hidden;
    background: var(--black-800);
    top: 0;
    left: -100vw;
    opacity: 0.6;
  }

  @keyframes animeLeft {
    to {
      opacity: 1;
      transform: initial;
    }
  }
}

.header_flexCard {
  display: flex;
  width: 100%;
  padding: 2rem 0;
  align-items: center;
  justify-content: space-between;

  p {
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  h2 {
    padding: 0 !important;
    margin: 0 !important;

    font-size: 1.5rem;
    color: var(--black-800);
    font-weight: 600;
  }
}
.details {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-top: 2rem;
  padding-right: 1rem;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
}
.close {
  padding-top: 0.5rem;

  border-bottom: 1px sold var(--gray-100);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background: transparent;
  svg {
    fill: var(--gray-500);
    transition: 0.7s ease-in;
    &:hover {
      fill: var(--primary);
    }
  }
}
.ico {
  bottom: 2rem;
  right: 2rem;
  position: absolute;

  animation: arrow 2s ease-in-out infinite;
}
.button_cancel {
  background: #ff0000;
  height: 3rem !important;
  display: flex;
  color: var(--white);
  padding: 1rem 0;
  border-radius: 0.5rem;
  align-items: center;
  justify-content: center;
}

@keyframes arrow {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.bottom {
  border: 1px solid var(--white);
}

@media (max-width: 420px) {
  .close {
    cursor: pointer;
    z-index: 12000;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .cardMovements {
    border: 1px solid var(--gray-100);
    border-radius: 0.5rem;
    height: 12rem;
    width: auto;
    margin: 0 0 0.5rem 0;

    div:nth-child(1) {
      display: flex;

      width: auto;
      flex-direction: column;
      justify-content: space-between;
      padding: 0.8rem;
      cursor: pointer;

      span {
        padding: 1rem;

        display: flex;
        align-items: center;
        width: 50%;
        height: 1.2rem;
        font-size: 0.9rem;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        width: auto;
        text-overflow: ellipsis;
      }

      span:nth-child(5) {
        display: none;
      }

      span:nth-child(6) {
        display: none;
      }

      span:nth-child(7) {
        div {
          padding: 0;
        }
      }
    }

    div:nth-child(2) {
      position: fixed;
      height: 100%;
      width: auto;
      top: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      background-color: var(--white);
      overflow: auto;

      opacity: 0;
      transform: translateX(-50px);
      animation: animeLeft 0.2s forwards;

      span {
        width: 100%;
        display: flex;
        font-size: 1rem;
        margin: 0.2rem 0 0.2rem 0;
        background-color: var(--gray-50);
        padding: 0.7rem;
        border-radius: 0.25rem;

        p {
          font-weight: bold;
          width: 8rem;
          margin-right: 1rem;
          text-align: right;
        }
      }

      &::after {
        position: relative;
        content: '';
      }

      &::before {
        content: '';
        width: 100vw;
        height: 100vh;
        position: absolute;
        background: black;
        top: 0;
        left: -100vw;
        opacity: 0.6;
      }

      @keyframes animeLeft {
        to {
          opacity: 1;
          transform: initial;
        }
      }

      @media (max-width: 420px) {
        div:nth-child(2) {
          width: 100%;

          &::after {
            content: '';
          }

          &::before {
            content: '';
          }
        }
      }

      span {
        width: 100%;
        display: flex;
        font-size: 1rem;
        margin: 0.2rem 0 0.2rem 0;
        background-color: var(--gray-50);
        padding: 0.7rem;
        border-radius: 0.25rem;

        p {
          font-weight: bold;
          width: 8rem;
          margin-right: 1rem;
          text-align: right;
        }
      }
    }

    svg {
      cursor: pointer;
    }
  }
}

.button {
  margin-top: auto;
  cursor: pointer;
  padding: 0.8rem 0;
  border-radius: 0.5rem;
  background: var(--primary);
  color: var(--white);
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.5rem;
  p {
    padding: 0.3rem;
    background: transparent;
  }
  &:disabled {
    opacity: 0.5;
    cursor: no-drop;
  }
}
.cancel {
  background: #ff0000;
  height: 3rem !important;
  display: flex;
  color: var(--white);
  padding: 1rem 0;
  border-radius: 0.5rem;
  align-items: center;
  justify-content: center;
  &:disabled {
    opacity: 0.5;
    cursor: no-drop;
  }
}
