@import '../../styles/_global';

.container {
  @extend .container_box;
}

.balances {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  background: var(--white);
  border-radius: 0.5rem;
}

.cards {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1fr);
  gap: 0.5rem;

  li {
    display: flex;
    align-items: center;
  }
}
.card {
  background: var(--white);
  border-radius: 1.25rem;
  width: 100%;
  height: auto;
  padding: 1rem 1rem 1rem 1.5625rem;
  box-shadow: 0rem 0.25rem 0.625rem rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  ul {
    width: 100%;
    display: grid;
    grid-template-columns: 80px 1fr 1fr 1fr 120px 1rem;
    gap: 1rem;
  }
}
.date_added {
  font-size: 12px;
  color: var(--gray-400);
  font-weight: 600;
}
.menu_actions {
  position: absolute;
  width: auto;
  top: 1.375rem;
  left: -13rem;
  z-index: 99;

  box-shadow: 0rem 0.25rem 0.625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  background: var(--white);
  height: auto;
  padding: 0.5rem;
}
.actions {
  display: flex;
  align-items: center;
  position: relative;
  div {
    margin-top: auto;
  }
}

.button_action {
  cursor: pointer;
  z-index: 1;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9;
  background: transparent;
  cursor: auto;
}
.button_actions {
  margin: 4px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 8px 12px 16px;
  border-radius: 8px;
  background: var(--white);
  width: 184px;
  height: 44px;
  font-size: 14px;
  color: var(--gray-850);
  &:hover {
    background: var(--gray-50);
  }
}
.menu_actions_desative {
  display: none;
}
.status_Active {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: var(--green);
}

.status_Disabled {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: var(--red-500);
}
.status_Alert {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: var(--yellow);
}

.name {
  color: var(--gray-950);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
}
.name_Blocked {
  color: var(--gray-400);
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 18px;
}
.font {
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
}
@mixin font-styles($color, $size, $weight) {
  font-size: $size;
  font-weight: $weight;
  color: $color;
}
.Active {
  color: var(--green);
  @extend .font;
}

.Alert {
  color: var(--yellow);

  @extend .font;
}
.Disabled {
  color: var(--gray-400);

  @extend .font;
}
.Blocked {
  color: var(--gray-400);
  cursor: pointer;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 0px;
}
.document {
  color: var(--gray-400);
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  width: 15rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wrapper_button_nav {
  display: flex;
  gap: 1rem;
}
.buttonNav {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8.86px;

  width: 1.8rem;
  height: 1.8rem;
  border-radius: 8px;

  color: var(--gray-300);
  font-style: normal;
  font-weight: 900;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  transition: 0.3s ease-in;
  background: transparent;
  &:hover {
    color: var(--primary-700);
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  margin: auto 0;
  border-radius: 50%;
  background: var(--primary);
  color: var(--white);
  .eyes {
    cursor: pointer;
  }
}

.loading {
  span {
    animation: dots 0.6s cubic-bezier(0.6, 0.1, 1, 0.4);
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  span:nth-child(0) {
    animation-delay: 0.1s;
  }

  span:nth-child(2) {
    animation-delay: 0.2s;
  }

  span:nth-child(3) {
    animation-delay: 0.3s;
  }

  span:nth-child(4) {
    animation-delay: 0.4s;
  }

  @keyframes dots {
    from {
      transform: translate3D(0, 0, 0);
    }
    to {
      transform: translate3D(0, 10px, 0);
    }
  }

  span {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--primary);
  }

  margin: 0 auto;
  text-align: center;
}

.box {
  display: flex;
  gap: 1rem;
}
.search {
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  gap: 2rem;
}

.card_balances {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
}

.search_container {
  width: 100%;
  padding: 2rem 0;
}

.row {
  width: auto;
  display: flex;
  align-items: center;
  color: var(--gray-500);

  select {
    color: var(--white);
    outline: none;
    border: none;
    font-size: 1rem;
    margin-left: 1rem;
    background: var(--primary);
    padding: 0.25rem 0.5rem;
    border-radius: 0.5rem;
  }
}

.wrapper__selection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  width: 100%;
  margin-bottom: 2rem;
}

.wrapper__selection_filters {
  display: flex;
  gap: 1rem;
}

.btn_styles {
  padding: 1rem 1.2rem 1rem 1.2rem;
  background: var(--white);
  @include font-styles(var(--gray-400), 0.8rem, 600);
}
.button_filter_active {
  @extend .btn_styles;
  border-bottom: 3px solid var(--primary);
}

.button_filter {
  @extend .btn_styles;
}

@media screen and (max-width: 420px) {
  .row {
    display: none;
  }
}

.listLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;

  div {
    font-size: 16px;
    color: var(--gray-500);

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }
}

.title {
  color: var(--gray-600);
  font-size: 1.5rem;
  font-weight: 600;
}
.wrapper_header {
  display: flex;
  padding: 1rem 0;
  justify-content: space-between;
  align-items: center;
}
