.button {
  color: var(--gray-500);
  border-radius: 0.25rem;
  padding: 0 1rem;
  height: 2rem;
  background: var(--gray-50);
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  transition: 0.3s ease;
  font-size: 0.8rem;
  &:hover {
    background: var(--gray-100);
    color: var(--gray-600);

    svg {
      fill: var(--gray-500);
    }
  }
  z-index: 9999;
  span {
    margin-right: auto;
  }
}

.button_disable {
  display: none;
}
