.button_select {
  background: transparent;
  cursor: pointer;
  height: 3rem;
  display: flex;
  align-items: center;
}
.eyes_ {
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
}
.container {
  display: grid;
  grid-template-columns: 2rem 10rem 1rem 8rem 3rem;
  align-items: center;
  right: 0;
  gap: 1rem;
  border-radius: 1rem;
  padding: 0.5rem 0.5rem;
  background: var(--white);
  border: 1px solid var(--gray-100);
}
.container_ {
  display: grid;
  grid-template-columns: 2rem 10rem 3rem;
  align-items: center;
  right: 0;
  gap: 1rem;
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  background: var(--white);
  border: 1px solid var(--gray-100);
}
.box_eys {
  display: flex;
  align-items: center;
  justify-content: center;
}
.title_pay {
  min-width: max-content;
  font-size: 0.7rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  color: var(--gray-500);
}
.separeted {
  width: 0.1rem;
  background: var(--gray-100);
  height: 2.5rem;
}
.pay {
  width: 2.5rem;
  height: 2.5rem;
  margin-left: auto;
  border-radius: 0.8rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-700);
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin-left: 0.1rem;
    }
  }
}

.balance {
  h2 {
    font-size: 0.8rem;
    color: var(--primary);
    font-weight: 400;
    padding: 0;
    width: 100%;
  }
  h3 {
    font-size: 0.8rem;
    color: var(--gray-700);
    padding: 0 0 0.5rem 0;
    width: 100%;
  }
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  flex-direction: column;
}

.view_balance {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--white);
  font-weight: bold;
  font-size: 1rem;
  color: var(--gray-850);
  p {
    font-weight: 600;
    font-size: 1.2rem;
  }
}

.view_balance_consignable {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--white);
  font-weight: 500;
  font-size: 1rem;
  color: var(--gray-850);
}

.balance_consignable {
  h3 {
    font-size: 0.8rem;
    color: var(--gray-500);
    width: 100%;
  }
  gap: 0.4rem;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
