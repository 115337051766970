.wrapper {
  width: 100%;
  display: flex;
  padding: 0.5rem 0;
  flex-direction: column;

  label {
    font-size: 0.8rem;
    margin-bottom: 0.1rem;
    color: var(--gray-500);
  }
  input {
    margin: 0.3rem 0;
    width: 100%;
    border: 1px solid var(--gray-100);
    height: 2rem;
    padding: 0 1rem;
    background: var(--gray-50);
    border-radius: 0.5rem;
    font-size: 0.75rem;
    &:disabled {
      opacity: 0.7;
    }

    &:focus {
      border: 2px solid var(--primary);
      transition: all 0.1s ease;
    }
    &:hover {
      border: 2px solid var(--primary-700);
      transition: all 0.1s ease;
    }
  }

  @media screen and (max-width: 767px) {
    height: 4rem;

    input {
      margin: 0;
    }
  }
}

.error__input {
  color: var(--red-500);
  font-size: 0.7rem;

  @media screen and (max-width: 767px) {
    margin-top: 0.5rem;
  }
}
