.button {
  background-color: var(--red-500);
  color: var(--white);
  border: none;
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  position: relative;
  transition: filter 0.3s ease;

  svg {
    margin-right: 8px;
  }

  &:hover {
    filter: brightness(0.6);
  }

  &:disabled {
    cursor: not-allowed;
    background-color: var(--gray-200);
  }

  &.confirming {
    background-color: var(--green);

    &:hover {
      filter: brightness(0.9);
    }
  }

  &.loading {
    background-color: var(--gray-200);
    color: #333;
    cursor: not-allowed;
    animation: color 1s linear infinite;

    svg {
      margin-right: 0;
    }
  }
}

.spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes color {
  0% {
    background-color: var(--gray-200);
  }
  100% {
    background-color: var(--primary);
  }
}
