.button {
  background: transparent;
  display: flex;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.2;
  }
}
