.container {
  display: flex;
  min-height: calc(100vh - 5rem);
  height: 100%;
  width: auto;
  border-radius: 0.5rem;

  flex-direction: column;
  form {
    background: var(--white);
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem 1.5rem;
    display: grid;
    grid-template-columns: 1fr;
    border-radius: 0.5rem;

    h1 {
      margin-bottom: 2rem;
    }
  }

  animation: blurSet 1s ease-in-out normal;

  @keyframes blurSet {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.box_1 {
  width: 100%;
  display: grid;
  grid-template-columns: 200px auto;
  gap: 1rem;

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    width: auto;
  }
}
.box_2 {
  width: 100%;
  display: grid;
  grid-template-columns: 300px auto;
  gap: 1rem;
  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    width: auto;
  }
}
.box_3 {
  width: 100%;
  display: grid;
  grid-template-columns: 150px auto 340px 140px;
  gap: 1rem;
  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    width: auto;
  }
}
.box_4 {
  width: 100%;
  display: grid;
  grid-template-columns: auto 120px auto auto;
  gap: 1rem;
  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    width: auto;
  }
  position: relative;
}

.box_5 {
  position: absolute;
  top: 5rem;
  right: 0;
  left: 7rem;
  margin: auto;
  width: 20rem;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    label {
      margin-left: 0.5rem;
      font-size: 0.875rem;
    }
  }
  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    width: auto;
  }
}
.wrapper_button {
  width: 100%;
  display: grid;
  grid-template-columns: 200px auto 290px 290px;
  gap: 1rem;
  @media screen and (max-width: 767px) {
    display: flex;
    width: auto;
  }
}
.title {
  padding: 2rem 0;
  color: var(--gray-900);
  background: var(--white);
  margin-bottom: 0.1rem;
  border-radius: 0.5rem;

  h1 {
    padding-left: 1rem;
  }
}
.title_ {
  h1 {
    font-size: 18px;
    color: var(--gray-900);
  }
}

.button {
  margin-top: 3rem;
  background: var(--primary);
  color: var(--white);
  font-size: 16px;
  padding: 1rem 1.5rem;
  width: 212px;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  &:disabled {
    opacity: 0.6px;
  }
  @media screen and (max-width: 767px) {
    width: auto;
  }
}
