@import '../../styles/_global';
.container_loading {
  display: flex;
  justify-content: center;
  align-items: center;

  background: var(--white);
  height: 100%;
  min-height: 100vh;
  width: calc(100% - 2rem);
  margin: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
}
.container {
  @extend .container_box;
}
.driverInfo {
  display: flex;
  flex-direction: column;

  header {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}
.menu {
  padding-top: 3rem;
}
.personalInfoUser {
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.account {
  width: 4rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  background: var(--green);

  @extend .center;
  @include font(var(--white), 0.8rem, 600);
}

.name {
  @include font(var(--gray-700), 1.5rem, 600);
  text-transform: capitalize;
}

.box {
  display: flex;
  gap: 2rem;
}
.container_info {
  border: 1px solid var(--gray-10);
  border-radius: 1rem;
  padding: 0.5rem;

  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.box_left {
  display: flex;
  flex-direction: column;
  width: 26rem;
}

.box_right {
  @extend .box_left;
  width: 30rem;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    width: 0.125rem;
    height: 100%;
    left: -0.5rem;
    background: var(--gray-10);
  }
}
.items_self {
  @include font(var(--gray-400), 0.8rem, 600);

  display: flex;
  align-items: center;
  height: auto;

  gap: 0.5rem;

  strong {
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }
}
.item_register {
  width: 8.5rem;
}
.items_ {
  @include font(var(--gray-400), 0.8rem, 600);

  display: flex;

  align-items: flex-start;
  height: auto;

  gap: 0.5rem;

  strong {
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }
}
.contract_modal {
  cursor: pointer;
}

.header {
  display: flex;
  gap: 1rem;
}

.button {
  width: 12rem;
  background: var(--white);
  @include font(var(--gray-400), 1rem, 600);
  text-transform: uppercase;
  padding: 1rem 0;
  cursor: pointer;
  @extend .center;

  position: relative;
  &::after {
    content: '';
    width: 100%;
    height: 0.3rem;
    background: transparent;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: all 0.3s ease-in-out;
    border-radius: 0.5rem;
  }
}

.button_ {
  width: 12rem;
  background: var(--white);
  @include font(var(--gray-600), 1rem, 600);
  text-transform: uppercase;
  padding: 1rem 0;
  cursor: pointer;
  @extend .center;

  position: relative;
  &::after {
    content: '';
    width: 100%;
    height: 0.3rem;
    background: var(--blue-700);
    position: absolute;
    bottom: 0;
    left: 0;
    transition: all 0.3s ease-in-out;
    border-radius: 0.5rem;
  }
}
.personalInfo {
  background-color: var(--white);
  border: 1px solid var(--gray-10);
  padding: 1rem;
  border-radius: 0 1rem 1rem 1rem;
}
.documentInfo {
  background-color: var(--white);
  border: 1px solid var(--gray-10);
  padding: 1rem;
  border-radius: 1rem;
}
.additional_data_json {
  margin: 0;
}
.vehicle_box_details {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.vehicleInfo {
  background-color: var(--white);
  border: 1px solid var(--gray-10);
  padding: 1rem;
  border-radius: 1rem;
  display: flex;
  gap: 3rem;
}
.items_vehicle {
  @include font(var(--gray-400), 0.8rem, 600);
  display: flex;

  align-items: flex-start;
  gap: 0.5rem;

  strong {
    display: flex;
    align-items: center;
    text-transform: capitalize;
    gap: 0.3rem;
  }
}
.personalInfo .infoRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.personalInfo .infoIow label {
  font-weight: bold;
}

.personalInfo .infoRow span {
  font-weight: normal;
}

.button__ {
  @include button_();
  &:hover {
    opacity: 0.9;
    color: var(--white);
  }
}

.wrapper_button {
  padding: 0;
  display: flex;
  align-items: center;
}

.img_wrapper {
  display: flex;
}

.img {
  width: auto;
  width: 22rem;
  height: 30rem;
}
.img_ {
  object-fit: cover;

  width: 22rem;
  height: 30rem;

  border-radius: 0.5rem;
  border: 1px solid var(--gray-10);
}

.img__ {
  object-fit: cover;
  width: 10rem;
  height: 10rem;
  border-radius: 0.5rem;
  border: 1px solid var(--gray-10);
}
.box_img {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  img {
    border-radius: 0.5rem;
  }
  button {
    width: 6rem;
    font-size: 1rem;
    border-radius: 0.5rem;
    background: var(--primary-700) !important;
    color: var(--white) !important;
    padding: 1rem 1.5rem !important;
    transition: 0.3s ease-in !important;
    &:hover {
      background-color: var(--primary) !important;
    }
  }
}

.tags {
  display: flex;
  @include font(var(--gray-400), 0.8rem, 600);
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;

  strong {
    background: var(--blue-50);
    color: var(--primary-700);
    padding: 0.5rem 0.5rem;
    border-radius: 0.5rem;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    button {
      background: transparent;
      cursor: pointer;
    }
  }
}

.items_img {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}
