.container {
  width: calc(100% - 2rem);
  height: 110%;
  margin: 1rem;
  background: var(--white);
  border-radius: 0.5rem;
  position: relative;
}

.box {
  width: 100%;
  margin: 0 auto;
  padding: 1rem 1rem;

  display: flex;

  animation: blurSet 1s ease-in-out normal;

  @keyframes blurSet {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
.box_title {
  color: var(--gray-800);
  font-weight: bold;
  font-size: 2rem;
  padding: 0 0 4rem 0;
}
.wrapper_button {
  display: flex;
  margin-left: auto;
  gap: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 7rem);

  animation: blurSet 1s ease-in-out normal;

  @keyframes blurSet {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.wrapper_button_navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6rem;
  button {
    color: var(--gray-850);
    padding: 1rem 1.5rem;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: 0.3s ease-in;
    background: var(--white);
    border: 2px solid var(--primary);
    &:hover {
      transform: scale(1.1);
    }
  }
}
