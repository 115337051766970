/* GroupOnboarding.module.scss */

.container {
  padding: 1.5rem;
  width: calc(100% - 2rem);
  margin: 1rem 1rem 0 1rem;
  background: var(--white);
  border-radius: 0.5rem;
  min-height: calc(100vh - 6rem);
  height: auto;
}

.header {
  color: var(--gray-800);
  font-size: 1.5rem;
  font-weight: 600;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.flex-large {
  width: 100%;
  height: auto;
  display: flex;
  background-color: transparent;
  border-radius: 5px;
  text-align: left;
  position: relative;
  display: flex;
  flex-direction: column;
}

.flex-large h2 {
  font-size: 20px;
  margin-bottom: 10px;
}
.header_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  align-items: flex-start;
  margin-top: 2rem;
}
.wrapper_actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  height: 2rem;
}
.wrapper_progress {
  width: 100%;
  gap: 0.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 0.25rem;
}
.flex-large form {
  margin: 10px 0;
}

.button {
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  border: none;
  padding: 1rem 1.7rem;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 0.8rem;
  transition: 0.3s ease-in;
  border: 1px solid var(--primary-700);
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    filter: brightness(0.97);
  }
}
.confirm {
  border: 1px solid var(--primary);
  border-radius: 0.5rem 0.5rem 0 0;
  padding: 0.6rem 1rem;

  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  h5 {
    font-size: 0.8rem;
    color: var(--primary);
  }
}

.button_ {
  @extend .button;
  color: var(--white);

  background-color: var(--primary-700);
  &:hover {
    filter: brightness(0.97);
    color: var(--white);
  }
}
.button_send {
  @extend .button;
  background-color: var(--primary-700);
  color: var(--white);
}
.drop {
  width: 100%;
}
.action_button {
  display: flex;
  height: 4.5rem;
  align-items: center;
  gap: 1rem;
  width: 100%;
  justify-content: space-between;
}

.header_notification {
  position: absolute;
  width: 100%;
  top: 0.3rem;
  border-radius: 0.5rem 0.5rem 0 0;
  padding: 0.625rem 1.25rem;
  background-color: var(--primary-700);
  display: flex;
  justify-content: space-between;
  align-items: center;
  h5 {
    font-size: 0.8rem;
    color: var(--white);
    font-weight: bold;
    padding-left: 1rem;
  }
  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    button {
      cursor: pointer;
      background-color: transparent;
      font-size: 0.8rem;
      color: var(--white);
      transition: 0.3s ease-in;
      &:hover {
        color: var(--gray-100);
      }
    }
  }
  animation: identifier 0.5s ease-in-out;
  @keyframes identifier {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
}
