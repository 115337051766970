@mixin font-styles($color, $size, $weight) {
  font-size: $size !important;
  font-weight: $weight !important;
  color: $color !important;
  line-height: 1rem !important;
}

.headerContainer {
  width: auto;
  height: 6rem;
  background: var(--white);
  border-bottom: 1px solid var(--gray-100);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
}
.headerContainer_ {
  width: auto;
  height: 6rem;
  background: var(--white);
  border-bottom: 1px solid var(--gray-100);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar {
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem 0 1em;
  margin-left: auto;
}
.company {
  height: 3rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
}
.name_ {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.welcome {
  font-size: 0.9rem;
  color: var(--gray-400);
}
.name__social {
  font-size: 0.9rem;
  color: var(--gray-900);
  margin: 0;
}
.company_ {
  align-items: center;
  height: 1.1rem;
  display: flex;
  gap: 0.3rem;
  h1 {
    @include font-styles(var(--gray-800), 1rem, 600);
    text-transform: uppercase;
  }
  div {
    @include font-styles(var(--gray-500), 1rem, 500);
  }
}
.pay {
  border: 2px solid var(--primary);
  border-radius: 8px;
  padding: 0.3rem 1rem;
  height: 2.5rem;
  display: flex;
  align-items: center;

  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.85rem;
    font-weight: 600;
    cursor: pointer;
  }
}
.profile {
  cursor: pointer;
  border: 2px solid var(--primary-700);
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  div:nth-child(1) {
    color: var(--primary-700);
    font-weight: 900;
    text-transform: uppercase;
    font-size: 1rem;
    display: flex;
    justify-content: flex-end;
  }
}
.balance {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.view_balance {
  min-width: 14rem;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: var(--white);
  font-weight: bold;
  font-size: 0.85rem;
  border-radius: 0.5rem;
  color: var(--gray-850);
}
.wrapper_button_menu_balances {
  display: flex;
  gap: 1rem;
}
.view_balance_ {
  font-size: 0.7rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  color: var(--gray-500);
}
.view_balance__ {
  min-width: 14rem;
  width: auto;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  font-size: 1.5rem;
  button {
    background: transparent;
    cursor: pointer;
  }
  p {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.eyes {
  width: 2.4rem;
  height: 1.5rem;
  display: flex;
  margin-top: auto;
  align-items: center;
  justify-content: center;
}
.logo {
  width: auto;
  height: 2rem;
  color: var(--primary);
}

.logout {
  height: 100%;
  margin-left: auto;
  display: flex;
  align-items: center;
  width: auto;
  gap: 1rem;
  justify-content: space-between;
  span {
    p {
      width: auto;
      font-weight: bold;
    }
    svg {
      size: 2rem;
      color: var(--primary);
    }
  }
}

//menu responsive

ul {
  list-style: none;
}
a {
  text-decoration: none;
  color: aliceblue;
}

.hamburger {
  display: none;
}

.hamburger_check {
  display: none;
}

.nav-bar {
  display: inline;
  position: fixed;
}

.list {
  display: none;
  justify-content: space-around;
  padding-top: 2em;
  padding-inline: 2em;
  font-size: 1.5em;
  background: var(--gray-850);
  z-index: 10;
}

.nav_bar > ul > li {
  position: relative;
}

.nav_bar > ul > li::after {
  transition: all 2s ease-in-out;
}

.nav_bar > ul > li:hover::after {
  position: absolute;
  top: 2em;
  left: 50%;
  width: 5px;
  height: 5px;
  border-radius: 100%;
  content: '';
  background-color: var(--white);
  transform: scale(100%);
}

.nav_bar > ul > li:not(:hover)::after {
  position: absolute;
  top: 2em;
  left: 50%;
  width: 5px;
  height: 5px;
  border-radius: 100%;
  content: '';
  background-color: transparent;
  transform: scale(0%);
}
.logoMenu {
  display: none;
}

@media screen and (max-width: 420px) {
  .balanceMobile {
    color: var(--white);
    font-size: 1.4rem;
  }
  .logoMenu {
    display: flex;
    width: 6rem;
    height: 2rem;
    color: var(--primary);
    margin-top: 1rem;
    position: absolute;
    right: 1rem;
  }
  nav {
    display: none;
  }
  .list {
    display: flex;
    z-index: 999;
  }
  .hamburger {
    margin-top: 1.2rem;
    margin-left: 2em;
    position: absolute;
    width: 2.4rem;
    height: 1.4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  li {
    font-size: 1rem;
    color: var(--white);
  }
  .hamburger_line {
    background-color: var(--white);
    height: 3px;
    width: 1.6rem;
    transition: all 250ms ease-in;
    border-radius: 25%;
  }

  .hamburger_check {
    display: block;
    position: absolute;
    margin-top: 1rem;
    margin-left: 2em;
    opacity: 0;
    width: 2.4rem;
    height: 2.4rem;
    z-index: 99;
  }
  .name {
    display: flex;
    flex-direction: column;
    color: var(--white);
    font-weight: bold;
    label {
      font-size: 0.7rem;
      font-weight: 400;
    }
  }
  .hamburger_check:not(:checked) ~ .hamburger > .hamburger_line_top {
    transform: translateY(0%);
  }

  .hamburger_check:checked ~ .hamburger > .hamburger_line_top {
    transform: translateY(350%) rotate(45deg);
  }

  .hamburger_check:not(:checked) ~ .hamburger > .hamburger_line_middle {
    opacity: 1;
  }

  .hamburger_check:checked ~ .hamburger > .hamburger_line_middle {
    opacity: 0;
  }

  .hamburger_check:checked ~ .hamburger > .hamburger_line_bottom {
    transform: translateY(-350%) rotate(-45deg);
  }
  .hamburger_check:checked ~ .hamburger > .hamburger_line {
    background: var(--primary);
    z-index: 999;
  }
  .hamburger_check ~ .list {
    display: none;
  }
  .hamburger_check:checked ~ .list {
    display: flex;
    height: 19rem;
    flex-direction: column;
    padding-bottom: 2rem;
  }

  .nav_bar {
    position: relative;
    height: 4rem;
    width: 100%;
    background-color: var(--gray-850);
    menu {
      z-index: 9;
      height: auto;
    }
  }

  .list {
    transition: all 250ms ease-in-out;
  }

  .hamburger_check:not(:checked) ~ ul {
    transform: translateY(-110%);
  }

  .nav_bar > ul {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100vh;
    padding: 0;
    margin: 0;
    font-size: 1em;
  }

  .nav_bar > ul > li:hover::after {
    display: none;
  }
}

.wrapper_view_balance {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.3rem;
  p {
    display: flex;
    align-items: center;
    font-weight: 500 !important;
    font-size: 1.3rem;
    color: var(--gray-800);
  }
}
