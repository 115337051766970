@import '../../../../styles/_global';
$size_circle: 0.8rem;
.cardOrder {
  height: 3.8rem;
  margin: 0.5rem 0;
  border-radius: 0.5rem;
  box-shadow: $shadow;
  border-radius: 1rem;
  background-color: var(--white);
  padding: 1rem;
  display: grid;
  @media screen and (min-width: 1900px) {
    grid-template-columns: 7.5rem 1.5fr 1fr 7.5rem 2fr 7.5rem 7.5rem 12rem 2rem;
  }
  @media only screen and (min-width: 768px) and (max-width: 1899px) {
    grid-template-columns: 7.5rem 1.5fr 1fr 7.5rem 1.5fr 6.5rem 5.5rem 12rem 2rem;
  }
  label {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.key {
  @include font(var(--gray-600), 0.8rem, 600);
  @extend .center;
  @include button-copy(var(--gray-50), var(--gray-400));
}

.eyes {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card_box {
  @extend .center;
}

.status {
  @extend .center;
  position: relative;
  &:hover > .error {
    display: flex;
    flex-direction: column;
  }
}

.span_date {
  @extend .center;
  @extend .font_styles_card;
}

.type {
  @extend .center;
  @extend .font_styles_card;
}

.description {
  @extend .center;
  @extend .font_styles_card;

  text-align: center;
}

.document {
  @extend .center;
  @extend .font_styles_card;
}

.percentage {
  @extend .center;
  @extend .font_styles_card;

  &:hover > .card {
    display: flex;
    flex-direction: column;
  }
}

.name {
  @extend .font_styles_card;

  text-align: center;
  padding: 0 1rem;

  transition: 0.3s ease-in;
  &:hover {
    @extend .font_styles_card;
  }
}

.used_amount {
  @extend .center;

  @include font(var(--green), 0.8rem, 600);

  width: 6.25rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.used_amount_ {
  @extend .center;

  @include font(var(--green), 0.8rem, 600);

  width: 6.25rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.not_used_amount {
  @extend .center;
  @extend .font_styles_card;

  padding: 0.5rem;
}

.card {
  height: auto;
  position: absolute;
  z-index: 99;
  bottom: 3rem;
  margin: auto;
  display: none;
  background-color: var(--white);
  border: 1px solid var(--gray-50);
  border-radius: 0.5rem;
  padding: 0.5rem;
  width: auto;
  margin: 0 auto;
  text-align: center;
  gap: 0.5rem;
  &::before {
    content: '';
    position: absolute;
    bottom: -0.7rem;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 20px;
    height: 20px;
    background-color: var(--white);
    border-right: 1px solid var(--gray-50);
    border-bottom: 1px solid var(--gray-50);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-radius: 0 0 4px 0;
  }
}

.error {
  @extend .card;
  width: 20rem;
  right: 0;
  &::before {
    display: none;
  }
}

.amount_ {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.summary-item {
  z-index: 99;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--gray-50);
  padding: 0.35rem;
  border-radius: 0.2rem;
  span {
    font-size: 0.9rem;
    font-weight: 600;
    color: var(--gray-600);
  }
}

.circle {
  width: $size_circle;
  height: $size_circle;
  border-radius: 50%;
}
.circle_red {
  @extend .circle;
  background-color: var(--red-500);
}
.circle_green {
  @extend .circle;
  background-color: var(--green);
}
.close {
  padding-top: 0.5rem;

  border-bottom: 1px sold var(--gray-100);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background: transparent;
  svg {
    fill: var(--gray-500);
    transition: 0.7s ease-in;
    &:hover {
      fill: var(--primary);
    }
  }
}
.button {
  margin-top: 2rem;
  cursor: pointer;
  padding: 0.2rem;
  border-radius: 0.5rem;
  background: var(--primary);
  color: var(--white);
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  p {
    padding: 0.3rem;
    background: transparent;
  }
  &:disabled {
    opacity: 0.5;
    cursor: no-drop;
  }
}
.flexCard {
  position: fixed;
  height: 100vh;
  width: 40%;
  top: 0;
  z-index: 9999;
  right: 0;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  padding: 0 1.5rem 1rem 1.5rem;

  opacity: 0;
  transform: translateX(-50px);
  animation: animeLeft 0.5s forwards;
  &::after {
    position: relative;
    content: '';
  }
  &::before {
    content: '';
    width: 100vw;
    height: 100vh;
    position: absolute;
    background: var(--black-800);
    top: 0;
    left: -100vw;
    opacity: 0.6;
  }

  @keyframes animeLeft {
    to {
      opacity: 1;
      transform: initial;
    }
  }
}
.details {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-top: 2rem;
  padding-right: 1rem;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none; /* Oculta a barra de rolagem no Chrome, Safari e Opera */
  }

  scrollbar-width: none; /* Oculta a barra de rolagem no Firefox */
}

.detailsUsedBox {
  display: flex;
  flex-direction: column;
  padding: 1rem;

  h4 {
    font-size: 0.875rem;
    font-weight: 600;
  }
}

.detailsUsed {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  min-width: 100%;
  border: 1px solid var(--gray-10);
  padding: 1rem;
  font-size: 0.75rem;
  border-radius: 0.5rem;
  margin-top: 1rem;
  background-color: var(--gray-5);

  div:last-child {
    border-bottom: 0;
    padding: 0;
  }

  strong {
    font-weight: 600;
  }
}

.detailsUsedContent {
  margin: 0.3rem 0 0.2rem;
  box-sizing: border-box;
  border-bottom: 1px dashed var(--gray-200);
  padding-bottom: 0.5rem;
  min-width: 100%;

  div {
    display: flex;
    gap: 5;

    strong {
      margin-right: 5px;
    }
  }
}

.header_flexCard {
  display: flex;
  width: 100%;
  padding: 2rem 0;
  align-items: center;
  justify-content: space-between;

  p {
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  h2 {
    padding: 0 !important;
    margin: 0 !important;

    font-size: 1.5rem;
    color: var(--black-800);
    font-weight: 600;
  }
}

.button_cancel {
  background: #ff0000;
  height: 3rem !important;
  display: flex;
  color: var(--white);
  padding: 1rem 0;
  border-radius: 0.5rem;
  align-items: center;
  justify-content: center;
}

.ico {
  bottom: 2rem;
  right: 2rem;
  position: absolute;

  animation: arrow 2s ease-in-out infinite;
}

@keyframes arrow {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.bottom {
  border: 1px solid var(--white);
}
