@import '../../styles/_global';
.menu {
  height: 100vh;
  width: calc(100% - 2rem);
  border-radius: 0.5rem;
  background: var(--white);
  scroll-behavior: smooth;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.4rem;

    padding: 0.5rem 0.5rem;

    //border-radius: 0.5rem;
    border-bottom: 2px solid var(--gray-100);
    // background-color: var(--gray-100);
    p:nth-child(1) {
      color: var(--gray-400);
      margin-bottom: 0.3rem;
    }
    color: var(--gray-600);
  }
}
.title {
  font-weight: bold;
  color: var(--gray-600);
  margin-bottom: 1rem;
  font-size: 1rem;
  margin-left: 1rem;
}
.menu {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  margin-top: 2rem;
  padding-left: 1rem;
  h1 {
    font-size: 1.8rem;
    font-weight: bold;
    color: var(--gray-600);
    margin-bottom: 1rem;
  }
}

.document {
  padding: 1rem;
  strong {
    padding-bottom: 0.5rem;
    @extend .title;
    margin-left: 0;
  }
  p {
    color: var(--gray-400);
    padding-top: 0.5rem;
    font-size: 1rem;
  }
}
.contact {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  strong {
    @extend .title;
    margin-left: 0;
    margin-bottom: 0.5rem;
  }
  p {
    color: var(--gray-400);
    font-size: 1rem;
  }
}

.back {
  padding-bottom: 2rem;
  display: flex;

  button {
    width: 6rem;
    display: flex;
    height: 2rem;
    justify-content: space-between;
    align-items: center;
    padding-right: 1rem;
    background: transparent;
  }
}
.info_ {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.accountable {
  padding: 1rem 0;
  height: auto;
  display: flex;
  flex-direction: column;
  strong {
    font-weight: 700;
    padding-bottom: 1rem;
  }
  p {
    font-size: 1.2rem;
  }
}
.adress {
  p {
    text-transform: capitalize;
  }
  padding: 1rem 0;
  height: auto;
  display: flex;
  flex-direction: column;
  strong {
    font-weight: 700;
    padding-bottom: 1rem;
  }
  p {
    font-size: 1.2rem;
  }
}

.wrapper_buttons {
  display: flex;
  margin-top: auto;
  color: var(--white);
  gap: 1rem;
  button {
    border-radius: 8px;
    height: 3rem;
    padding: 1rem 1.5rem;
    background: var(--primary);
    transition: 0.3s ease-in;
    &:hover {
      background-color: var(--primary-700);
    }
  }
}

.info {
  margin-bottom: 2rem;
  h3 {
    @extend .title;
    padding: 0 0.5rem;
    font-size: 1rem;
    margin-left: 0.5rem;
    margin-top: 1rem;
  }
  li {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-start;
    align-items: center;

    padding: 0.2rem 1rem;
    font-size: 0.8rem;
    border-bottom: 1px solid transparent;
    p {
      color: var(--gray-400);
      margin-top: 0;
    }
    strong {
      color: var(--gray-400);

      font-weight: 600;
    }
  }
}
