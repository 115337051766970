@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('rsuite/dist/rsuite.min.css');
@import url('./_global.scss');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 1rem;
  font-family: 'Inter', sans-serif;
  font-optical-sizing: auto;

  font-style: normal;
  font-variation-settings: 'slnt' 0;
}
.containerStyles {
  max-width: 80%;
  margin: 0 auto;
  padding: 0 20px 40px 2px;
}
:root {
  --bg-content: #f7f7fa;
  --white: #fff;
  --black-800: #242424;
  --gray-5: #f9f9f9;
  --gray-10: #f7f7f7;
  --gray-20: #f2f2f9;
  --gray-50: #f6f7f9;
  --gray-100: #edeef1;
  --gray-200: #d7dae0;
  --gray-300: #b3b9c6;
  --gray-400: #8a94a6;
  --gray-500: #667085;
  --gray-600: #565e73;
  --gray-700: #464c5e;
  --gray-800: #3d424f;
  --gray-850: #222633;
  --gray-900: #363a44; //primary
  --gray-950: #24262d;
  --primary: #051532; //primary
  --primary-700: #31519a;
  --purple-800: #380195;
  --green: #40b66c;
  --red-500: #ff326a;
  --yellow: #ff8c00;
  --purple: #9e00e9;
  --blue-700: #31519a;
  --blue-500: #4496fc;
  --blue-50: #ecf8ff;
  --shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.15);
  --blue-status: #eff8ff;
  --green-status: #ecfdf3;
  --orange-status: #fff6ed;
  --blue-dark-status: #f0f9ff;
  --red-status: #fef3f2;
  --purple-status: #f4f3ff;
  --yellow-status: #fffaeb;

  --line-table: #e4e5e5;
  --bg-table: #f5f6f7;

  --blue-text: #175cd3;
  --green-text: #17a84c;
  --orange-text: #c4700a;
  --blue-dark-text: #026aa2;
  --red-text: #e52012;
  --purple-text: #5925dc;
  --yellow-text: #facc00;

  --rs-primary-500: #051532;
  --rs-steps-state-process: #1650ab;
  --rs-input-focus-border: #1650ab;
  --rs-bg-active: #1650ab;
  --rs-btn-primary-bg: #1650ab;
  --rs-btn-primary-hover-bg: #051532;
  --rs-btn-link-text: #1650ab;

  .rs-input {
    background-color: #fff;
    background-color: var(--rs-input-bg);
    background-image: none;
    border: 1px solid #e5e5ea;
    border: 1px solid var(--rs-border-primary);
    border-radius: 0.375rem;
    color: #343434;
    color: var(--rs-text-primary);
    display: block;
    font-size: 0.9rem;
    line-height: 1.42857143;
    padding: 0.4375rem 0.6875rem;
    padding-right: 0.6875rem;
    -webkit-transition: border-color 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out;
    width: 100%;
  }
  .rs-picker-toggle-textbox {
    background-color: #fff;
    background-color: var(--rs-input-bg);
    border: 1px solid rgba(0, 0, 0, 0);
    color: #343434;
    color: var(--rs-text-primary);
    height: 100%;
    left: 0;
    outline: none;
    padding-left: 0.625rem;
    padding-right: 2rem;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .rs-btn-close .rs-icon {
    display: block !important;
  }
  .rs-picker-default .rs-picker-toggle .rs-btn {
    padding-bottom: 0.5625rem;
    padding-top: 0.5625rem;
  }
  .rs-picker-default
    .rs-picker-toggle.rs-btn
    .rs-picker-toggle-indicator
    .rs-picker-caret-icon,
  .rs-picker-default
    .rs-picker-toggle.rs-btn
    .rs-picker-toggle-indicator
    .rs-picker-clean {
    top: 25%;
  }
  .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-caret-icon,
  .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-clean {
    right: 0.75rem;
    top: 0.5rem;
  }
  .rs-input-group {
    border: 1px solid var(--gray-200);
    border-radius: 0.5rem;
    color: var(--gray-500);
    color: var(--rs-text-primary);
    cursor: text;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-transition: border-color 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out;
    width: 100%;
    height: 3rem;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-addon {
    background: none;
    border: none;
    border-left-color: currentcolor;
    border-left-style: none;
    border-left-width: medium;
    padding: 0.625rem 0.75rem;
    width: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
  }

  .rs-picker-toggle-placeholder {
    font-size: 0.9rem;
  }

  .rs-picker-toggle {
    border: 1px solid var(--gray-200);
    border-radius: 0.5rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  .rs-picker-toggle-indicator .rs-picker-clean.rs-btn-close {
    padding: 0.25rem 0;
    svg {
      margin-top: 0.2rem;
      width: 1.2rem;
      height: 1.2rem;
    }
  }
}

iframe {
  display: none;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-family: 'Inter', sans-serif;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

h1 {
  font-size: 2rem;
  font-weight: 400;
}

h2 {
  font-size: 1.5rem;
}

button {
  cursor: pointer;
  border: none;
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}

// reset css
.drop-zone {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6rem;
  cursor: pointer;
  border: 1px var(--gray-400) dashed;
  border-radius: 0.5rem;
  width: 80%;
  color: var(--gray-400);
  font-size: 0.85rem;
  margin: 1rem 0;
  &:hover {
    color: var(--gray-500);

    border: 1px var(--gray-500) dashed;
  }
}
html,
body {
  color-scheme: light;
  background: var(--bg-content);
  -webkit-font-smoothing: antialiased;
  font-size: 16px; /* Tamanho de fonte padrão */

  /* Mobile */
  @media screen and (max-width: 767px) {
    font-size: 14px !important;
  }

  /* Tela HD (720p e 1080p) */
  @media screen and (min-width: 1280px) and (max-width: 1819px) {
    font-size: 13px !important;
  }

  /* Tela Full HD (1080p e acima) */
  @media screen and (min-width: 1820px) {
    font-size: 18px !important; /* Ajuste opcional para Full HD */
  }
}

.font {
  font-size: 1.5rem;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  border-bottom: 1px solid gray;
}
