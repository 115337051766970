@import '../../../../styles/_global';

.container {
  width: 100%;
  height: 2.5rem;
  background: var(--white);
  border-radius: 0.5rem;
  display: grid;
  grid-template-columns: 1.5rem 1fr 2rem 10rem 7rem 16rem 10rem 6rem 7rem 2rem;
  gap: 1rem;
  padding: 0 1rem;
  margin-bottom: 0.5rem;
}
.font_header {
  @extend .font_styles_card;
  text-transform: uppercase;
}
.date {
  @extend .center;
  @extend .font_header;
}
.phone {
  @extend .center;
  @extend .font_header;
}
.document {
  @extend .center;
  @extend .font_header;
}
.type {
  @extend .center;
  @extend .font_header;
}

.debit {
  @extend .center;
  @extend .font_header;

  justify-content: end;
}
.name {
  @extend .center;
  justify-content: flex-start;
  span {
    @extend .font_header;
  }
}
.status {
  @extend .center;

  span {
    @extend .center;
    @extend .font_header;
  }
}
.info {
  color: var(--gray-600);
  a {
    @extend .center;
    background: var(--gray-50);
    width: 2rem;
    height: 2rem;
    padding: 0;
    margin: 0;
    border-radius: 0.5rem;
    transition: 0.3s ease-in;
    &:hover {
      background-color: var(--gray-75);
    }
  }
}
.input {
  @extend .input_select_all_blue;
}
