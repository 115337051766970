@import '../../styles/_global';

.container {
  @extend .container_box;
  scroll-behavior: smooth;
}

.info {
  border: 1px solid transparent;
}

.main {
  display: flex;
  gap: 2rem;
  height: 100%;
}

.balance_fat {
  height: 12rem;
  width: 36rem;
  display: block;

  h2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 0;
    color: var(--gray-700);
    /* Balance/Big */
    font-size: 2rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }

  hr {
    margin: 0;
  }
}
.balance_fat_ {
  @extend .balance_fat;
  height: auto;
}

.box {
  padding: 1rem 1rem;
  display: flex;
  border-radius: 1rem;
  border: 1px solid var(--gray-50);
}
.info_account {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  padding: 1rem 0;
}
.balance {
  border: 1px solid var(--gray-50);
  border-radius: 1rem;
  padding: 1rem;
  height: 10rem;

  min-width: 17rem;
  display: flex;
  flex-direction: column;

  h4 {
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: var(--gray-600);
  }
  strong {
    margin-top: 0.2rem;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    color: var(--gray-700);
  }
}

.balance__ {
  @extend .balance;
  width: 28rem;
  height: 34rem;
}

.content__balance_fat {
  display: flex;
  flex-direction: column;
  width: 70%;
  h4 {
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: var(--gray-600);
  }
  strong {
    margin-top: 0.2rem;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    color: var(--gray-700);
  }
}
.form {
  height: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem 0;
  h1 {
    color: var(--gray-600);
  }
  p {
    font-size: 0.8rem;
    color: var(--gray-400);
    padding-bottom: 2rem;
    line-height: 1rem;
  }
  h1 {
    font-weight: bold;
    color: var(--gray-600);
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
}
.wrapper_buttons {
  display: block;
}
.wrapper_buttons_ {
  display: flex;
  gap: 1rem;
  width: 50%;
}
.button {
  margin-top: 0.5rem;
  background-color: var(--primary-700);
  border-radius: 0.9rem;
  display: flex;
  padding: 0.8857rem 1.55rem;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5536rem;
  flex: 1 0 0;
  align-self: stretch;
  color: var(--white);
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.52px;
  text-transform: uppercase;
}
.button_open {
  height: 2rem;
  margin-top: 0.5rem;
  background-color: var(--primary-700);
  border-radius: 0.9rem;
  display: flex;
  padding: 0.8857rem 1.55rem;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5536rem;
  flex: 1 0 0;
  align-self: stretch;
  color: var(--white);
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.0325rem;
  text-transform: uppercase;
}
.button_close {
  height: 3rem;
  margin-top: 0.5rem;
  background-color: var(--white);
  border: 2px solid var(--primary-700);
  border-radius: 0.9rem;
  display: flex;
  width: 10rem;

  padding: 0.5rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5536rem;

  align-self: stretch;
  color: var(--primary-700);
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.0325rem;
  text-transform: uppercase;
}
.button_ {
  margin-top: 0.5rem;
  background-color: var(--white);
  border: 2px solid var(--primary-700);
  border-radius: 0.9rem;
  display: flex;
  width: 10rem;

  padding: 0.5rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5536rem;

  align-self: stretch;
  color: var(--primary-700);
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.0325rem;
  text-transform: uppercase;
}
.preview {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  h1 {
    font-size: 1.2rem;
    margin: 0;
  }
}
.title {
  font-weight: 600;
  font-size: 1.2rem;
  padding-left: 1rem;
  padding-bottom: 1rem;
}
