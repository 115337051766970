@import '../../styles/_global';

.sectionContainer {
  @extend .container_box;

  main {
    display: block;
  }
  @media (max-width: 420px) {
    margin: 0.8rem 0;
  }
}

.header {
  height: 4rem;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  width: 100%;
}
.title {
  margin-bottom: 1rem;
  font-size: 1.6rem;
  font-weight: 600;
}
.description {
  font-size: 0.9rem;
  color: var(--gray-400);
  font-weight: 400;
}
.wrapper_search {
  padding: 1.5rem 0;
  width: 100%;
  display: flex;
}
.upload {
  display: flex;
  flex-direction: column;
  .buttonContainer {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
  }
}

.wrapper__buttons {
  button {
    margin-right: 1rem;
  }
}
.wrapper_button_actions {
  display: flex;
  width: 10rem;
  align-items: center;
  justify-content: space-between;
}
.wrapper_pagination {
  width: 100%;
  display: flex;
  height: 2rem;
  justify-content: space-between;
  align-items: center;
}

.button__affect {
  svg {
    color: var(--primary);
  }

  button {
    &:disabled {
      opacity: 0.5;
      cursor: no-drop;
    }
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  margin-right: 1rem;
}
.reload {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 4px;
  background-color: var(--primary);

  svg {
    stroke: var(--white);
    fill: var(--white);
  }
  &:hover {
    opacity: 0.7;
  }
}
.aprove {
  display: flex;
  height: 2rem;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 4px;
  width: auto;
  background-color: var(--primary);
  color: white;
  font-size: 0.9rem;

  &:hover {
    opacity: 0.7;
  }
}
.contentLoad {
  border-radius: 0.5rem;
}

.send {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  border-radius: 4px;
}

.upload {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  padding: 1rem 0;
}

.button {
  width: 100%;
  display: grid;
  grid-template-columns: 100px 1fr;
}

.cardMovements {
  border: 1px solid var(--gray-100);
  margin: 0 2rem 0.5rem 2rem;
  border-radius: 0.5rem;

  div:nth-child(1) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }
  div:nth-child(2) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--gray-100);
    padding: 1rem;
  }
  svg {
    cursor: pointer;
  }
}

.font {
  font-size: 0.8rem;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cardHeader {
  margin: 0 2rem 0.5rem 2rem;
  border-radius: 0.5rem;
  box-shadow: var(--shadow);
  div {
    display: grid;
    width: 100%;
    grid-template-columns: 100px 1fr 1fr 150px 150px 120px;
    height: 4rem;
    align-items: center;
    justify-content: space-between;
  }
}

.heading__cards {
  @extend .center;
  @extend .font;
  color: var(--gray-600);
  font-weight: 600;
}
.heading__status {
  @extend .center;
  @extend .font;
  color: var(--gray-600);
  font-weight: 600;
  padding: 0 1rem;
}
.arowPages {
  margin: 1rem 2rem 1rem 2rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  div {
    display: flex;
    margin-right: 1rem;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
    padding: 10px;
    width: 300px;
    height: 100px;
    flex-direction: column;
  }
}
.textPage {
  font-size: 1rem;
  margin-right: 0.5rem;
  color: var(--gray-900);
}
.loading {
  cursor: wait;
  opacity: 0.6;
  height: 100vh;
}

.back {
  display: flex;
  align-items: center;
  height: 2rem;
  width: 100px;
  cursor: pointer;
  justify-content: space-around;
  margin-bottom: 1rem;
  color: var(--primary);
  svg {
    stroke: var(--primary);
    fill: var(--primary);
  }
  margin-right: 2rem;
}
.wrapper__selection {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.listLoader {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.message {
  width: calc(100% - 7.3rem);
  margin-left: 1rem;
  background-color: var(--purple-400);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  color: var(--white);
  height: 1.1rem;
  position: absolute;
  border-radius: 0 0 1rem 1rem;
}

.error {
  width: calc(100% - 9.3rem);
  margin-left: 1rem;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  color: var(--white);
  height: 1.1rem;
  position: absolute;
  border-radius: 0 0 1rem 1rem;
}
@media (max-width: 420px) {
  .container {
    width: 100%;
    margin-left: -2rem;
    height: auto;
    main {
      margin: 0;
    }
  }
  .upload {
    padding: 1rem 0 1rem 0;
  }
  .arowPages {
    margin: 0;
    margin-bottom: 4rem;
    width: 100%;
    align-items: flex-end;
    div {
      margin: 1px 0;
    }
    input {
      display: none;
    }
  }
  .back {
    display: flex;
    justify-content: flex-start;
    margin-left: 0.5rem;
    span {
      display: none;
    }
  }
  .cardHeader {
    display: none;
  }
  .input {
    width: 100%;
    margin: 1rem 0 1rem 0;
  }
  .wrapper_pages {
    margin-bottom: 3rem;
    margin-top: 1rem;
  }
  .message {
    width: 100%;
    margin-left: 1rem;
    background-color: var(--purple-400);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    color: var(--white);
    height: 1.1rem;
    position: absolute;
    border-radius: 0 0 1rem 1rem;
  }

  .error {
    width: 100%;
    margin-left: 1rem;
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    color: var(--white);
    height: 1.1rem;
    position: absolute;
    border-radius: 0 0 1rem 1rem;
  }
}

.payment_info {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  height: 12rem;
  color: var(--gray-200);
}
