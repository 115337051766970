.logo {
  width: 10rem;
  height: 10rem;
}

.container {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 9999999;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
