.error {
  position: fixed;
  top: 4rem;
  right: 0;
  margin-top: 5rem;
  z-index: 999;
  background: var(--red-500);
  height: 4rem;
  width: 8em;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  border-radius: 8px 0 0 8px;
  color: var(--gray-50);
  font-weight: bold;
  overflow: hidden;
}
.animeLeft {
  opacity: 0;
  transform: translatex(50px);
  animation: animeLeft 0.9s forwards;
}

@keyframes animeLeft {
  to {
    opacity: 1;
    transform: initial;
  }
}
@media screen and (max-width: 420px) {
  .error {
    width: 20rem;
    font-size: 1rem;
  }
}
.button {
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
  background: transparent;

  svg {
    path {
      fill: var(--white) !important;
    }
  }
}
