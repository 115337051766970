.container {
  display: flex;
  width: 100%;
  align-items: flex-end;
  gap: 1rem;
}

.awarming {
  display: flex;
  gap: 0.5rem;
  font-weight: 600;
  font-size: 1rem;
  color: var(--yellow-text);
  p {
    font-size: 0.875rem;
    font-weight: bold;
    margin-right: 0.5rem;
  }
}
//details
.box_description {
  border-radius: 0.5rem;
  display: flex;
  width: 32rem;
  flex-direction: column;
  gap: 0.5rem;
}

.box_info_ {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  border-radius: 0.5rem;
}
.box_info__ {
  display: flex;
  gap: 0.2rem;
  border-radius: 0.5rem;
}
.box_info {
  margin-left: auto;
  margin-bottom: 0.3rem;
  background: var(--gray-10);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  margin-top: auto;
  gap: 0.5rem;
  border-radius: 0.5rem;

  span {
    display: flex;
    gap: 0.5rem;
    font-size: 0.875rem;
    font-weight: 600;

    p {
      font-weight: 300;
      font-size: 0.875rem;
    }
  }
}
//resume
.box_resume {
  border-radius: 0.5rem;
  display: flex;
  margin-left: auto;
  flex-direction: column;
  gap: 0.5rem;
}
.summary-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  p {
    font-size: 0.875rem;
    font-weight: bold;
    margin-right: 8px;
  }

  span {
    font-weight: 600;
    font-size: 1rem;
    color: var(--green-text);
  }
}
.summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  p {
    font-size: 0.875rem;
    font-weight: bold;
    margin-right: 8px;
  }

  span {
    font-weight: 600;
    font-size: 1rem;
    color: var(--primary);
  }
}
.summary-item-process {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  p {
    font-size: 0.875rem;
    font-weight: bold;
    margin-right: 8px;
  }

  span {
    font-weight: 600;
    font-size: 1rem;
    color: var(--blue-dark-text);
  }
}
.summary-item-error {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  p {
    font-size: 0.875rem;
    font-weight: bold;
    margin-right: 0.5rem;
  }

  span {
    font-weight: 600;
    font-size: 1rem;
    color: var(--red-text);
  }
}
.summary-item-await {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  p {
    font-size: 0.875rem;
    font-weight: bold;
    margin-right: 0.5rem;
  }

  span {
    font-weight: 600;
    font-size: 1rem;
    color: var(--orange-text);
  }
}

.alert {
  border: 3px solid #ffc0c0;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  width: 100%;
  min-width: 20rem;
  background: #fff0f0;
  strong {
    font-weight: 600;
  }
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
  p {
    font-weight: 600;
    color: #ff0000;
  }
}
