.container {
  background: var(--white);
  height: auto;
  width: calc(100% - 2rem);
  border-radius: 0.5rem;
}
.driverInfo {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.personalInfo {
  background-color: #fff;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  p {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--gray-500);
  }

  div {
    border: 1px solid var(--gray-100);
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: 2rem;
  }
  h2 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: capitalize;

    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      background: #ecfdf3;
      color: var(--green);
      padding: 0.5rem 1rem;
      border-radius: 1rem;
      font-size: 0.875rem;
    }
  }
}
.personalInfo .infoRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.personalInfo .infoIow label {
  font-weight: bold;
}

.personalInfo .infoRow span {
  font-weight: normal;
}

.button {
  background: transparent;
  border: none;
  display: flex;
  font-weight: bold;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 0;
}
