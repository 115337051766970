//global
$shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.15);

//bordas
$br: 1px solid red;

@mixin font-styles($color, $size, $weight) {
  font-size: $size;
  font-weight: $weight;
  color: $color;
}

@mixin circle($background, $color) {
  p {
    background: $background;
    width: auto;
    color: $color;
    padding: 0.5rem 0.5rem;
    border-radius: 8px;
  }
}
@mixin button-copy($background, $color) {
  button {
    background: $background;
    width: auto;
    color: $color;
    padding: 0.5rem 0.5rem;
    border-radius: 8px;
    cursor: pointer;
  }
}
.font {
  font-size: 0.8rem;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
