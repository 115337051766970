@import '../../styles/_global';
.container {
  width: calc(100% - 2rem);
  min-height: 100vh;
  height: 100%;
  margin: 1rem;
  margin-bottom: auto;
  padding-top: 1rem;
  padding: 1rem;
  background: var(--white);
  border-radius: 1rem;
  h1 {
    font-weight: bold !important;
    color: var(--black-800);
    padding: 2rem 0;
  }
  padding-bottom: 2rem;
}
.cards {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.title {
  margin-top: 1rem;
  font: 1.3rem;
  color: var(--gray-800);
  font-weight: bold;
  width: 100%;
}
.card {
  border: 1px solid var(--gray-100);
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.amount {
  width: 23rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;

  font-size: 1.5rem;
  font-weight: bold;
  color: var(--gray-800);

  input {
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    border: 1px solid var(--gray-50);
  }
  span {
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
  }
}
.scheduled {
  font-size: 0.8rem;
}
.action {
  display: flex;
  gap: 1rem;
}
.confirm {
  border: none;
  padding: 0.5rem 0.5rem;
  color: var(--white);
  background: var(--primary-700);
  border-radius: 1rem;
  transition: 0.3s ease-in;
  &:hover {
    filter: brightness(1.3);
  }
}

.cancel {
  @extend .confirm;
  border-bottom: 2px solid var(--red-500);
  color: var(--red-500);
  background: transparent;
  border-radius: 0;
}

.edit {
  background: transparent;
  margin-left: auto;
  &:disabled {
    opacity: 0.3;
    cursor: no-drop;
  }
}
