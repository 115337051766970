@import '../../../../styles/_global';
.header_style_ {
  @extend .header_style;
  padding: 0;
}
.title {
  padding-bottom: 1rem;
  font-weight: 600;
}
.box_response {
  padding: 2rem 0;
}

.box_header {
  padding-bottom: 1rem;

  span {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.table_container {
  width: 100%;
  margin-top: 0;
  font-size: 0.8rem;

  td,
  th {
    border: 1px solid var(--line-table); /* Adicione a borda de 1px de espessura em preto */
    padding: 0.6rem 0.5rem;
    vertical-align: middle;
    text-align: center;
  }

  th {
    padding: 0.8rem 0.5rem;
    text-align: center;
    vertical-align: middle;
    color: var(--gray-500);
  }
  td {
    color: var(--gray-700);
  }
  th,
  td {
    height: 3.2rem;
  }
  th:nth-child(1),
  td:nth-child(1) {
    width: 14rem;
  }
  th:nth-child(2),
  td:nth-child(2) {
    width: 14rem;
  }
  th:nth-child(3),
  td:nth-child(3) {
    width: 15rem;
  }
  th:nth-child(4),
  td:nth-child(4) {
    width: 10rem;
  }
  th:nth-child(5),
  td:nth-child(5) {
    width: 12rem;
  }
  th:nth-child(6),
  td:nth-child(6) {
    width: 10rem;
  }
  th:nth-child(7),
  td:nth-child(7) {
    width: 4rem;
  }
  th:nth-child(8),
  td:nth-child(8) {
    width: 4rem;
  }
  th:nth-child(9),
  td:nth-child(9) {
    width: 4rem;
  }
  th:nth-child(10),
  td:nth-child(10) {
    width: 4rem;
  }
  thead {
    background: var(--bg-table);
    height: 3.25rem;
  }
}

.active {
  width: 1.2rem;
  height: 1.2rem;
  background: var(--green);
  margin: 0 auto;
  border-radius: 50%;
}

.not_active {
  width: 1.2rem;
  height: 1.2rem;
  background: var(--red-text);
  margin: 0 auto;
  border-radius: 50%;
}
