.container {
  padding: 2rem 2rem 1rem 2rem;
  margin: 1rem 1rem;
  animation: boxheight 0.7s ease-in;
  background: var(--white);

  border-radius: 1rem;
}
.title {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 1rem 0;
  padding: 0 0 1rem 0;
}

@keyframes boxheight {
  0% {
    opacity: 0;
    height: 0rem;
  }
  25% {
    opacity: 0;
    height: 5rem;
  }
  50% {
    opacity: 0.2;
    height: 10rem;
  }
  75% {
    opacity: 0.6;
    height: 13rem;
  }
  100% {
    opacity: 1;
    height: 16rem;
  }
}
