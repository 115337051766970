.box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--gray-10);
  border-radius: 1rem;
  padding: 0.87rem;
}

.box_ {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid var(--gray-10);
  border-radius: 1rem;
  padding: 1rem;
  gap: 0.5rem;
}

.header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.header_ {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}

.left_card {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  p {
    margin: 0 !important;
  }
}
.left_card_ {
  width: 100%;
  padding: 1rem;
  background: var(--gray-10);
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  p {
    margin: 0 !important;
  }
}
.value {
  font-size: 0.75rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.title {
  font-size: 0.875rem;
  font-weight: 600;
}
.key {
  button {
    background: transparent;
  }
}
.edit {
  background: transparent;
}
