@import '../../../../styles/_global';

.container {
  margin-bottom: 2rem;
}

.table_container {
  width: 100%;
  margin-top: 0;
  font-size: 0.8rem;

  td,
  th {
    border: 1px solid var(--line-table); /* Adicione a borda de 1px de espessura em preto */
    padding: 0.6rem 0.5rem;
    vertical-align: middle;
    text-align: center;
  }

  th {
    padding: 0.8rem 0.5rem;
    text-align: center;
    vertical-align: middle;
    color: var(--gray-500);
  }
  td {
    color: var(--gray-700);
  }
  th,
  th {
    height: 2.7rem;
  }
  th:nth-child(1),
  td:nth-child(1) {
    width: 2rem;
  }
  th:nth-child(2),
  td:nth-child(2) {
    width: 4rem;
  }
  th:nth-child(3),
  td:nth-child(3) {
    width: 9.375rem;
  }
  th:nth-child(4),
  td:nth-child(4) {
    width: 20rem;
  }
  th:nth-child(5),
  td:nth-child(5) {
    width: 10rem;
  }
  th:nth-child(6),
  td:nth-child(6) {
    width: 6.25rem;
  }
  th:nth-child(7),
  td:nth-child(7) {
    width: 4rem;
  }
  th:nth-child(8),
  td:nth-child(8) {
    width: 3rem;
  }
  th:nth-child(9),
  td:nth-child(9) {
    width: 6rem;
    text-align: start;
  }
  th:nth-child(10),
  td:nth-child(10) {
    width: 3rem;
  }

  thead {
    background: var(--bg-table);
    height: 3.25rem;
  }
}
.status {
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: min-content;
  }
}
.eyes {
  cursor: pointer;
}
.table-container th {
  background-color: transparent;
}

.actions-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  height: 100%;
  width: 100%;
}

.button {
  height: 1.1875rem;
  width: 1.1875rem;
  vertical-align: top;
  border-radius: 0.2rem;
  margin-right: 0.3125rem;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 0.5;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.button_icon {
  background-color: transparent;
}

.edit_button {
  @extend .button;
  background-color: transparent;
}

.delete_button {
  @extend .button;
  background-color: transparent;
}

.no-users {
  font-style: italic;
  color: #777;
}

.color_error {
  color: var(--orange-text);
}

.percentagem {
  position: relative;
  cursor: pointer;
  &:hover > .card {
    cursor: pointer;
    display: flex;
    flex-direction: column;
  }
}

.card {
  height: auto;
  position: absolute;
  z-index: 99999999;
  bottom: 3rem;
  left: 50%;
  transform: translateX(-50%);
  margin: auto;
  display: none;
  background-color: var(--gray-50);
  border: 0.0625rem solid var(--gray-100);
  border-radius: 0.5rem;
  padding: 0.5rem;
  width: max-content;
  margin: 0 auto;
  text-align: center;
  &::before {
    content: '';
    position: absolute;
    bottom: -0.7rem;
    left: 50%;
    margin: auto;
    transform: translateX(-50%) rotate(45deg);
    width: 1.25rem;
    height: 1.25rem;
    background-color: var(--gray-50);
    border-right: 0.0625rem solid var(--gray-100);
    border-bottom: 0.0625rem solid var(--gray-100);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-radius: 0 0 4px 0;
    @media screen and (min-width: 1900px) {
      right: 4.5rem;
    }
  }

  .summary-item:not(:last-child) {
    border-bottom: 0.0625rem solid var(--gray-100);
  }
}

.summary-item {
  z-index: 99;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
  border: 1px solid var(--gray-50);
  padding: 0.35rem;
  border-radius: 0.2rem;
  span {
    font-size: 0.9rem;
    font-weight: 600;
    color: var(--gray-400);
  }
}

.amount_ {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  span {
    font-weight: 600;
    color: var(--gray-800);
  }
}

$size_circle: 0.8rem;
.circle {
  width: $size_circle;
  height: $size_circle;
  border-radius: 50%;
}

.circle_red {
  @extend .circle;
  background-color: var(--red-text);
}

.circle_green {
  @extend .circle;
  background-color: var(--green-text);
}

.circle_blue {
  @extend .circle;
  background-color: var(--blue-text);
}

.circle_orange {
  @extend .circle;
  background-color: var(--orange-text);
}

.tags {
  @extend .center;
  @include font(var(--gray-400), 0.8rem, 600);
  justify-content: center;
  align-items: center;
  position: relative;
  span {
    display: flex;
    align-items: center;
    gap: 1rem;

    p {
      background: var(--blue-50);
      color: var(--primary-700);
      padding: 0.5rem 0.5rem;
      border-radius: 50%;
      font-weight: 400;
      cursor: pointer;
    }

    p:hover + .card_tag {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      z-index: 7;
    }

    strong {
      background: var(--blue-50);
      color: var(--primary-700);
      padding: 0.5rem 0.5rem;
      border-radius: 0.5rem;
      font-weight: 400;
    }
  }
}

.card_tag {
  height: auto;
  position: absolute;
  left: 1rem;
  z-index: 2;
  bottom: 3rem;
  margin: auto;
  display: none;
  background-color: var(--white);
  border: 1px solid var(--gray-10);
  border-radius: 0.5rem;
  padding: 0.5rem;
  width: 24rem;
  margin: 0 auto;
  text-align: center;
  gap: 0.5rem;
  &::before {
    z-index: 1;
    content: '';
    position: absolute;
    bottom: -0.6rem;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 0.75rem;
    height: 0.75rem;
    background-color: var(--white);
    border-right: 1px solid var(--gray-10);
    border-bottom: 1px solid var(--gray-10);
    border-top-left-radius: 0.1875rem;
    border-top-right-radius: 0.1875rem;
    border-radius: 0 0 0.25rem 0;
  }
}
