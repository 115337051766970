// Variáveis globais
$primary-color: #0b0b52;
$background-color: #fff;
$overlay-color: rgba(0, 0, 0, 0.6);
$gray-color: #ccc;
$selected-color: $primary-color;

// Overlay do modal
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $overlay-color;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000000;
}
.modalOverlay_ {
  display: none;
}

// Conteúdo do modal

.modalContent_form {
  background: $background-color;
  padding: 2rem;
  width: 37.5rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
}
.modalContent {
  background: $background-color;
  padding: 2rem;
  width: 35rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
}
.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.title,
.heading {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.description {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 1.5rem;
}

// Opções de avaliação (CSAT, NPC)
.ratingContainer {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  margin: 1rem 0;

  .ratingButton {
    background: #f4f4f4;
    border: 1px solid $gray-color;
    border-radius: 5px;
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1rem;
    cursor: pointer;
    transition: ease 0.3s;

    &.selected {
      background: $selected-color;
      color: #fff;
      border: none;
    }
  }
}

// Formulário comum
.form {
  display: flex;
  flex-direction: column;
  align-items: center;

  .commentBox {
    width: 100%;
    height: 100px;
    border: 1px solid $gray-color;
    border-radius: 5px;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    resize: none;

    &:focus {
      outline: 2px solid $primary-color;
    }
  }

  .charCount {
    font-size: 0.8rem;
    color: #888;
    align-self: flex-end;
    margin-bottom: 1rem;
  }

  .submitButton {
    background: $primary-color;
    color: #fff;
    border: none;
    padding: 0.8rem 2rem;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: ease 0.3s;

    &:hover {
      background: darken($primary-color, 10%);
    }
  }
}

.form_ {
  display: flex;
  flex-direction: column;
  align-items: center;

  .commentBox {
    width: 100%;
    height: 100px;
    border: 1px solid $gray-color;
    border-radius: 5px;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    resize: none;

    &:focus {
      outline: 2px solid $primary-color;
    }
  }

  .charCount {
    font-size: 0.8rem;
    color: #888;
    align-self: flex-end;
    margin-bottom: 1rem;
  }

  .submitButton {
    background: $primary-color;
    color: #fff;
    border: none;
    padding: 0.8rem 2rem;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: ease 0.3s;

    &:hover {
      background: darken($primary-color, 10%);
    }
  }
}

// Bloco de perguntas (NPC específico)
.questionBlock {
  margin-bottom: 1.5rem;

  .questionText {
    font-weight: bold;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: flex-start;
  }

  .answerBox {
    width: 100%;
    height: 2.5rem;
    border: 1px solid $gray-color;
    border-radius: 5px;
    padding: 0.5rem;

    &:focus {
      outline: 2px solid $primary-color;
    }
  }
}

.questionBlock_ {
  width: 100%;
  margin-bottom: 1.5rem;

  .questionText {
    font-weight: bold;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: flex-start;
  }

  .answerBox {
    width: 100%;
    height: 2.5rem;
    border: 1px solid $gray-color;
    border-radius: 5px;
    padding: 0.5rem;

    &:focus {
      outline: 2px solid $primary-color;
    }
  }
}
// Múltipla escolha
.multipleChoice {
  text-align: left;
  margin-bottom: 1.5rem;

  .options {
    display: flex;
    flex-direction: column;

    label {
      margin: 0.5rem 0;
      display: flex;
      align-items: center;

      input[type='radio'] {
        margin-right: 0.5rem;
      }
    }

    .otherOption {
      display: flex;
      align-items: center;

      .otherInput {
        margin-left: 0.5rem;
        flex: 1;
        border: 1px solid $gray-color;
        border-radius: 5px;
        padding: 0.3rem;
      }
    }
  }
}

// Wrapper de botões
.wrapper_buttons {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}
.ratingLabels {
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 1rem;
}

.feedbackOptions {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;
  gap: 0.5rem;

  .feedbackOption {
    background: #f4f4f4;
    border: none;
    border-radius: 50%;
    font-size: 1.5rem;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background 0.3s;

    &.selected {
      background: $primary-color;
      color: #fff;
    }
  }
}
.selectedFeedbackLabel {
  font-size: 0.9rem;
  margin-bottom: 1rem;
  color: $primary-color;
  font-weight: bold;
}
