.container {
  width: auto;
  height: 10.5rem;
  padding: 2rem 2rem;
  background: var(--white);
  border-radius: 1rem;
  box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.15);
}
.box {
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    strong {
      color: var(--var-600);
      font-weight: 600;
      font-size: 1rem;
    }
    span {
      color: var(--var-400);
      font-weight: 400;
      font-size: 0.8rem;
      margin-bottom: 0;
    }
  }
  h4 {
    color: var(--var-600);
    font-weight: 600;
    font-size: 1.2rem;
  }
  h1 {
    color: var(--var-600);
    font-weight: 900;
    font-size: 2.3rem;
  }
}

.loading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-30px);
}
