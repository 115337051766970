.container {
  width: 100%;
}

.font {
  font-size: 0.8rem;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.box {
  display: flex;
  width: 6rem;
  cursor: pointer;
  button {
    @extend .font;
    @extend .center;
    background: transparent;
    color: var(--gray-400);
    gap: 1rem;
    transition: 0.3s ease-in;
    &:hover {
      color: var(--gray-500);
    }
  }
}
