.modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 10001;
  display: flex;
}
.modal_content {
  border-radius: 0.5rem;
  width: 36rem;
  min-height: 16rem;
  padding: 2rem 2.5rem;
  margin: auto;
  background-color: var(--white);
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  img {
    align-self: center;
  }

  h2 {
    font-size: 1.5rem;
    color: var(--gray-800);
    font-weight: bold;
    margin: 0;
    text-align: center;
  }

  ul {
    box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.15);

    li {
      margin-top: -1px;
      border: 1px solid var(--gray-200);
      padding: 0.4rem 0.8rem;
      background-color: var(--white);
      cursor: pointer;

      &:first-child {
        border-radius: 5px 5px 0px 0px;

        p {
          p {
            strong {
              color: var(--primary);
            }
          }
        }
      }

      &:last-child {
        border-radius: 0px 0px 5px 5px;
      }

      div {
        display: flex;
        justify-content: space-around;
        animation: boxheight 0.7s ease-in;
        cursor: auto;

        div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 1rem auto;
        }

        ul {
          width: 86%;
          box-shadow: none;
          list-style: circle;
          padding: 0.6rem;

          li {
            border: none;
            background-color: var(--white);
            cursor: auto;

            &:nth-child(3) {
              display: list-item;
            }

            span {
              strong {
                font-weight: bold;
                color: var(--primary);
              }
            }
            &:nth-child(4) {
              span {
                font-size: 0.8rem;
              }
            }
          }
        }
      }

      &:nth-child(3) {
        display: flex;
        flex-direction: column;

        div {
          display: flex;
          flex-direction: column;
          gap: 2rem;
          margin: 2rem auto;
          padding: auto 0.3rem;
          align-items: center;

          h4 {
            font-size: 1.2rem;
            font-weight: bold;
            color: var(--primary);
          }

          p {
            display: inline;
            font-size: 0.9rem;
            text-align: justify;

            strong {
              font-weight: bold;
              color: var(--primary);

              &:first-of-type {
                font-size: 1rem;
                opacity: 0.9;
                color: var(--gray-800);
              }
            }
          }
        }
      }
    }
  }

  p {
    font-size: 1rem;
    line-height: 1.2rem;
    font-weight: 300;
    display: flex;
    justify-content: flex-start;
    gap: 0.3rem;
    text-align: justify;

    strong {
      font-weight: bold;
    }
  }

  .button {
    padding: 1rem 2rem;
    border-radius: 0.5rem;
    font-size: 0.9rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    background: var(--primary);
    color: var(--white);
    opacity: 0.9;
    margin: 1rem auto;
    display: block;

    &:disabled {
      opacity: 0.5;
    }

    &:hover {
      opacity: 1;
    }
  }
}

@keyframes boxheight {
  0% {
    opacity: 0;
    height: 0rem;
  }
  25% {
    opacity: 0;
    height: 1rem;
  }
  50% {
    opacity: 0;
    height: 4rem;
  }
  75% {
    opacity: 0.2;
    height: max-content;
  }
  100% {
    opacity: 1;
    height: max-content;
  }
}
