.container {
  min-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .footerActions {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 0;
    padding-top: 2rem;

    .actionsRow {
      width: 26.25rem;
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      padding: auto 50px;
      flex-wrap: nowrap;
    }

    .actionsColumn {
      width: 13.75rem;
      display: flex;
      justify-content: center;
      gap: 1rem;
      padding: auto 50px;
    }

    .back {
      width: 3.75rem;
      height: 3.75rem;
      border-radius: 20rem;
      background-color: #1761eb;
      color: var(--white);
      border: 1px solid #1761eb;
      font-size: larger;
    }

    .download {
      width: 3.75rem;
      height: 3.75rem;
      border-radius: 20rem;
      background-color: var(--white);
      border: 1px solid var(--gray-200);
      color: var(--black-800);
      font-size: larger;
    }
  }
}
