.container {
  background: var(--white);
  display: flex;
  align-items: center;
  gap: 1rem;
  width: auto;
  height: 2.5rem;
  font-size: 0.85rem;
  padding: 1rem 0;
  justify-content: space-between;
  border-radius: 4px;
  color: var(--gray-400);
  cursor: pointer;
  position: relative;
  margin-right: 1rem;
}
.menu {
  position: absolute;
  top: 2.5rem;
  right: 0;
  width: 18rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 0.5rem;
  padding: 0.5rem;

  background: var(--white);
  box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.15);
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9;
  background: transparent;
  cursor: auto;
}
