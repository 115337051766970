.loading {
  width: 100%;
  background: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    width: 3rem;
    height: 3rem;
    background: transparent;
    border: 6px solid var(--gray-100);
    border-top-color: var(--primary);
    border-radius: 50%;
    animation: isRotation 0.9s ease infinite;
  }

  @keyframes isRotation {
    to {
      transform: rotate(1turn);
    }
  }
}
