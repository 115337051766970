.tag-input-container {
  background: var(--white);

  border: 0.0625rem solid var(--gray-10);
  background: var(--gray-10);

  padding: 1.5rem;
  border-radius: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  width: auto;
  input {
    border: none;
    outline: none;
    flex-grow: 1;
    min-width: 6.25rem;
    margin-top: 0.3125rem;
    &:disabled {
      opacity: 0.7;
    }

    :focus {
      border-color: var(--gray-50);
      transition: all 0.1s ease;
    }
    &:hover {
      transition: all 0.1s ease;
    }
  }
  input::placeholder {
    color: var(--gray-200);
  }

  @media screen and (max-width: 767px) {
    height: 4rem;

    input {
      margin: 0;
    }
  }
}
.close {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  cursor: pointer;
  padding: 0.5rem;
}
.title {
  font-size: 1.25rem;
  font-weight: 600;
  padding-bottom: 1rem;
}
.selected-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.tag {
  background-color: var(--blue-50);
  border-radius: 1rem;
  padding: 0.5rem 0.5rem;
  display: flex;
  gap: 0.5rem;
  margin: 0;
  align-items: center;
  font-size: 0.8rem;
  color: var(--primary-700);
  font-weight: 500;
  border: 1px solid var(--primary-700);
  span {
    margin: 0;
  }
}

.remove-tag {
  cursor: pointer;
  margin-left: 8px;
}

.dropdown {
  position: absolute;
  z-index: 6;
  top: 3rem;
  left: 0;
  width: 100%;
  gap: 0.625rem;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--gray-50);
  border-radius: 0.25rem;
  max-height: 14rem;
  overflow-y: auto;
  background-color: var(--white);
  padding: 0.5rem;
  width: 100%;
  min-height: 0px;
}

.dropdown_item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  padding: 1rem;
  background: var(--gray-10);
  cursor: pointer;

  &:hover {
    background-color: var(--gray-50);
  }
}

.dropdown_added {
  button {
    background-color: var(--blue-50);
    border: 1px solid var(--primary-700);
    border-radius: 0.5rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    color: var(--primary-700);

    strong {
      color: var(--primary-700);
      font-weight: 600;
    }
  }
}
.wrapper_input {
  position: relative;
  background: var(--white);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border: 1px solid var(--gray-10);
  padding: 0.3125rem;
  border-radius: 0.5rem;
  height: auto;
  margin-bottom: 1rem;
  gap: 0.5rem;
  min-height: 3rem;
}

.button_include {
  padding: 1rem;
  color: var(--white);
  background: var(--primary-700);
  border-radius: 0.5rem;
  width: auto;
  max-width: 12rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  &:disabled {
    opacity: 0.7;
  }
}
