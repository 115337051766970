.cardMovements {
  box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.15);
  margin: 0 2rem 0.5rem 2rem;
  border-radius: 1rem;
  height: 4rem;
}

.heading {
  height: 100%;
  display: grid;
  grid-template-columns: 6.25rem 1fr 1fr 9.375rem 9.375rem 9rem;
  width: auto;
  padding: 0;
  cursor: pointer;
  color: var(--gray-900);
}

.font {
  font-size: 0.8rem;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.heading__cards {
  @extend .center;
  @extend .font;
  color: var(--gray-400);
  font-weight: 600;
}
.heading__name {
  @extend .center;
  @extend .font;
  color: var(--gray-600);
  font-weight: 900;
}
.heading__status {
  @extend .center;
  @extend .font;
  justify-content: end;
  padding: 0 1rem;
}
.button {
  cursor: pointer;
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
  background: var(--primary);
  color: var(--white);
  font-weight: bold;
  display: flex;
  font-size: 0.8rem;
  align-items: center;

  gap: 1rem;
  height: 3rem;
  p {
    padding: 0.3rem;
    background: transparent;
  }
}
.close {
  width: 100%;
  padding-top: 0.5rem;
  height: 4rem;
  border-bottom: 1px sold var(--gray-100);
  svg {
    fill: var(--gray-500);

    &:hover {
      fill: var(--primary);
    }
  }
}
.wrapper_cards {
  display: flex;
  gap: 1rem;
}
.payment {
  display: flex;
  flex-direction: column;
}
.wrapper_button {
  padding: 1rem 0;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}
@media (max-width: 420px) {
  .close {
    z-index: 99;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .cardMovements {
    border: 1px solid var(--gray-100);
    border-radius: 0.5rem;
    height: 12rem;
    width: auto;
    margin: 0 0 0.5rem 0;
    div:nth-child(1) {
      display: flex;

      width: auto;
      flex-direction: column;
      justify-content: space-between;
      padding: 0.8rem;
      cursor: pointer;

      span {
        padding: 1rem;

        display: flex;
        align-items: center;
        width: 50%;
        height: 1.2rem;
        font-size: 0.9rem;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        width: auto;
        text-overflow: ellipsis;
      }
      span:nth-child(5) {
        display: none;
      }
      span:nth-child(6) {
        display: none;
      }
      span:nth-child(7) {
        div {
          padding: 0;
        }
      }
    }
    div:nth-child(2) {
      position: fixed;
      height: 100%;
      width: auto;
      top: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      background-color: var(--white);
      overflow: auto;

      opacity: 0;
      transform: translateX(-50px);
      animation: animeLeft 0.2s forwards;
      span {
        width: 100%;
        display: flex;
        font-size: 1rem;
        margin: 0.2rem 0 0.2rem 0;
        background-color: var(--gray-50);
        padding: 0.7rem;
        border-radius: 0.25rem;
        p {
          font-weight: bold;
          width: 8rem;
          margin-right: 1rem;
          text-align: right;
        }
      }
      &::after {
        position: relative;
        content: '';
      }
      &::before {
        content: '';
        width: 100vw;
        height: 100vh;
        position: absolute;
        background: black;
        top: 0;
        left: -100vw;
        opacity: 0.6;
      }

      @keyframes animeLeft {
        to {
          opacity: 1;
          transform: initial;
        }
      }

      @media (max-width: 420px) {
        div:nth-child(2) {
          width: 100%;

          &::after {
            content: '';
          }
          &::before {
            content: '';
          }
        }
      }
      span {
        width: 100%;
        display: flex;
        font-size: 1rem;
        margin: 0.2rem 0 0.2rem 0;
        background-color: var(--gray-50);
        padding: 0.7rem;
        border-radius: 0.25rem;
        p {
          font-weight: bold;
          width: 8rem;
          margin-right: 1rem;
          text-align: right;
        }
      }
    }
    svg {
      cursor: pointer;
    }
  }
}

.flexCard {
  position: fixed;
  height: 100vh;
  width: auto;
  top: 0;
  z-index: 999900009;
  right: 0;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  padding: 0 1rem 1rem 1rem;

  opacity: 0;
  transform: translatex(-50px);
  animation: animeLeft 0.5s forwards;
  &::after {
    position: relative;
    content: '';
  }
  &::before {
    content: '';
    width: 100vw;
    height: 100vh;
    position: absolute;
    background: black;
    top: 0;
    left: -100vw;
    opacity: 0.6;
  }

  @keyframes animeLeft {
    to {
      opacity: 1;
      transform: initial;
    }
  }
  span {
    width: 100%;
    display: flex;
    margin: 0.2rem 0 0.2rem 0;
    background-color: var(--gray-50);
    padding: 0.5rem 0.5rem;
    border-radius: 0.25rem;
    @extend .font;
    color: var(--gray-500);

    p {
      font-weight: bold;
      width: 8rem;
      margin-right: 0.5rem;
      text-align: left;
      @extend .font;
      color: var(--gray-600);
    }
    h2 {
      font-weight: bold;
      width: 8rem;
      margin-right: 0.5rem;
      text-align: left;
      color: var(--gray-600);
      font-size: 1rem;
      margin-bottom: 0;
    }
  }
}
