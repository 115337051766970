@import '../../styles/_global';

.container {
  @extend .container_style;
  display: flex;
  flex-direction: column;
  background: var(--white);
}
.header_style_ {
  @extend .header_style;
  padding: 0 1rem;
}
.input_select {
  @extend .input_select_blue;
  margin-bottom: 0.4rem;
}
.form {
  display: flex;
  height: auto;
  flex-direction: column;
  border-radius: 0.5rem;
  gap: 1rem;
  background-color: var(--white);
  border: 1px solid var(--gray-100);
  margin: 0 1rem;
  padding: 1.5rem 1rem;
}
.form_box {
  display: flex;
  gap: 1rem;
  align-items: flex-end;
}
.wrapper_button {
  display: flex;
  justify-content: flex-end;
}
.button {
  padding: 1rem;
  color: var(--white);
  background: var(--primary-700);
  border-radius: 0.5rem;
  width: auto;
  height: 2.7rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  transition: 0.7s ease-in;
  &:hover {
    filter: brightness(-1.3);
  }
  &:active {
    transform: scale(0.75); /* Escala para 95% quando clicado */
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); /* Adiciona uma sombra sutil */
  }
}
.edit_form {
  display: flex;
  width: 40rem;
  gap: 1rem;
  align-items: flex-end;
  padding: 0.5rem 0;
}
.wrapper {
  display: flex;
  padding: 0 0;
  flex-direction: column;

  label {
    font-size: 0.9375rem;
    margin-bottom: 0.1rem;
    color: var(--gray-950);
    font-weight: 500;
  }
  input {
    margin: 0.3rem 0;
    width: 20rem;
    border: 1px solid var(--gray-20);
    height: 3rem;
    padding: 0 1rem;
    background: var(--white);
    border-radius: 0.5rem;
    font-size: 0.9375rem;
    &:disabled {
      opacity: 0.7;
    }

    :focus {
      border-color: var(--gray-20);
      transition: all 0.1s ease;
    }
    &:hover {
      border: 2px solid var(--gray-20);
      transition: all 0.1s ease;
    }
  }
  textarea {
    margin: 0.3rem 0;
    width: 41rem;
    border: 1px solid var(--gray-20);
    height: 8rem;
    padding: 0.5rem;
    background: var(--white);
    border-radius: 0.5rem;
    font-size: 0.9375rem;
    &:disabled {
      opacity: 0.7;
    }

    :focus {
      border-color: var(--gray-20);
      transition: all 0.1s ease;
    }
    &:hover {
      border: 2px solid var(--gray-20);
      transition: all 0.1s ease;
    }
  }

  @media screen and (max-width: 767px) {
    height: 4rem;

    input {
      margin: 0;
    }
  }
}
.title {
  padding: 1.5rem 0;
}

//select

.select {
  margin-bottom: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.9rem 1rem;
  border-radius: 0.5rem;
  border: 1px solid var(--primary-700);
  position: relative;
  select {
    all: initial;
    width: 100%;
    z-index: 2;
    font-family: 'Roboto', sans-serif;
    color: var(--gray-600);
  }
  svg {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
  margin-top: 1rem;
}
.select_type {
  max-width: 20rem;
}
.select_type_ {
  display: none;
}
