@import '../../styles/_global';

.container {
  @extend .container_box;
}
.button__ {
  @include button_();
  &:hover {
    opacity: 0.9;
    color: var(--white);
  }
}
.title {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  padding-bottom: 1rem;
}

.isActive {
  display: flex;
  align-items: center;
}
.wrapper_pagination {
  width: 100%;
  display: flex;
  height: 2rem;
  justify-content: space-between;

  div:first-child {
    margin-left: auto;
    margin-right: 2rem;
  }

  div:nth-child(2) {
    width: 8rem;
    justify-content: flex-end;
  }
}
.wrapper_button_isActive {
  display: flex;
  width: 100%;
  max-width: 16rem;
  button {
    margin-right: 1rem;
  }
}
.wrapper__button {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  padding: 2rem 0;

  div:nth-child(1) {
    margin-right: auto;
  }
  @media screen and (max-width: 420px) {
    flex-direction: column;
    justify-content: flex-end;
    div:nth-child(1) {
      margin-right: 0 auto;
    }
  }
}

.isActive_ {
  background: transparent;
  width: 7rem;

  height: 3rem;
  display: flex;
  align-items: center;
  button {
    background: transparent;
    margin-right: 2rem;
    color: var(--gray-500);
    @media screen and (max-width: 420px) {
      p {
        color: var(--gray-500);
        padding-left: 2px;
        font-size: 0.8rem;
      }
    }
  }
  padding-bottom: calc(0.5rem + 3px);
}

.title_active {
  padding-left: 4px;
  color: var(--gray-500);
}
.listLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 20rem);

  h5 {
    font-size: 16px;
    color: var(--gray-500);

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
}
.isActive_true {
  background: transparent;
  border-bottom: 3px solid var(--primary);
  padding-bottom: 0.5rem;
  width: 7rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media screen and (max-width: 420px) {
    p {
      padding-left: 2px;
      color: var(--gray-300);
      font-size: 0.8rem;
    }
  }
}
.customerStatus {
  margin-left: 0.5rem;
  font-weight: bold;
  color: var(--white);
  background: var(--primary);
  padding: 0.25rem;
  border-radius: 0.25rem;
}

@media screen and (max-width: 420px) {
  .container {
    padding: 1rem;
  }
  .wrapper__button {
    bottom {
      margin-left: 1rem;
    }
  }
  .wrapper_pagination {
    width: 10rem;
    display: flex;
    height: 2rem;
    justify-content: space-between;
  }
  .wrapper_button_isActive {
    display: flex;
    width: 100%;
    max-width: 16rem;
  }
  .search {
    font-size: 0.8rem;
  }

  .send {
    margin-left: 1rem;
    padding: 1rem 1rem;
  }
}

.button {
  background-color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.8rem;
  transition: 0.3s ease-in;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    filter: brightness(0.9);
  }
}
