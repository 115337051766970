.modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
}
.modal_content {
  border-radius: 0.5rem;
  width: 32rem;
  height: 16rem;
  padding: 1rem;
  margin: auto;
  background-color: var(--white);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  gap: 1rem;
  h2 {
    font-size: 1.5rem;
    color: var(--gray-800);
    font-weight: bold;
    margin: 0;
  }
  p {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2rem;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
}

.cancel {
  padding: 0.3rem 1rem;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  background: transparent;
  color: var(--primary-700);
  border-bottom: 0.1875rem solid var(--primary-700);
  &:disabled {
    opacity: 0.5;
  }
}

.button {
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  background: var(--primary-700);
  color: var(--white);

  &:disabled {
    opacity: 0.5;
  }
}
