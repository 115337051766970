@mixin font-styles($color, $size, $weight) {
  font-size: $size;
  font-weight: $weight;
  color: $color;
}
.isActive_ {
  background: transparent;
  width: auto;
  height: 3rem;
  padding: 1rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.25rem solid var(--gray-100);

  @media screen and (max-width: 420px) {
    width: 4rem;
    margin: 0 1rem;
    margin-bottom: 2rem;
  }
  .title_active {
    @include font-styles(var(--gray-300), 0.8rem, 600);
  }
  .customerStatus {
    margin-left: 0.5rem;
    font-weight: bold;
    color: var(--gray-300);
    background: var(--gray-10);
    padding: 0.5rem 0.8rem;
    border-radius: 0.5rem;
    font-size: 0.8rem;
  }
}

.isActive_true {
  background: transparent;

  width: auto;
  height: 3rem;

  //  border-radius: 8px;

  padding: 1rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 0.25rem solid var(--primary-700);

  @media screen and (max-width: 420px) {
    width: 4rem;
    margin: 0 1rem;
    margin-bottom: 2rem;

    p {
      padding-left: 2px;
      color: var(--gray-400);

      font-weight: 600;
      font-size: 0.8rem;
    }
  }

  .title_active {
    @include font-styles(var(--primary-700), 0.8rem, 600);
  }
  .customerStatus {
    margin-left: 0.5rem;
    font-weight: bold;
    color: var(--primary-700);
    background: var(--blue-50);
    padding: 0.5rem 0.8rem;
    border-radius: 0.5rem;
    font-size: 0.8rem;
  }
}
