@import '../../styles/styles';
.container {
  height: calc(100vh - 6rem);
  width: calc(100% - 2rem);
  border-radius: 1rem;
  background: var(--white);
  margin: 1rem 1rem 0 1rem;
  padding: 1rem;
  scroll-behavior: smooth;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  border-radius: 8px;
  box-shadow: $shadow;
  width: 30rem;
  height: auto;
  padding: 2rem 2rem 0 2rem;
  h1 {
    font-weight: bold;
    color: var(--gray-600);
    font-size: 1.5rem;
    padding-bottom: 1rem;
  }
}

.button {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 0.85rem;
  width: 100%;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  background: var(--primary);
  color: var(--white);
  border-radius: 0.5rem;
  transition: 0.7s ease;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: var(--primary-700);
  }
}

.wrapper_button {
  display: flex;
  gap: 1rem;
  a {
    text-align: center;
  }
}
