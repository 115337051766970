.wrapper__input {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 6rem;
  height: 3rem;
  @media (max-width: 420px) {
    position: static;
    width: 100%;
  }
  input {
    height: 3rem;
    width: 100%;
    border: 1px solid var(--gray-200);
    border-radius: 0.5rem;
    outline: none;
    background: transparent;
    color: var(--gray-850);
    font-size: 0.8rem;
    text-indent: 1rem;
    transition: all 0.35s;
    margin-bottom: 0 !important;
    &:focus {
      border-color: (--gray-200);
    }
    &:focus ~ svg {
      fill: (--gray-200);
    }
    &:focus ~ .label {
      background: var(--white);
      color: var(--gray-200);
      transform: translateY(-1.8rem);
    }
  }
}
.label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: var(--gray-200);
  left: 0.65rem;
  padding: 0.25rem 0.5rem;
  pointer-events: none;
  transition: all 0.35s;
  font-size: 0.8rem;
  @media (max-width: 420px) {
    position: fixed;
    z-index: -99;
  }
}
.isValue {
  position: absolute;
  top: 50%;
  left: 0.65rem;

  color: var(--gray-400);
  transition: all 0.35s;
  padding: 0.25rem 0.5rem;
  pointer-events: none;
  font-size: 0.8rem;
  background: var(--white);
  transform: translateY(-1.8rem);
  @media (max-width: 420px) {
    display: none;
  }
}
.icon {
  top: 50%;
  right: 1rem;
  position: absolute;
  transform: translateY(-50%);
  color: var(--gray-200);
  transition: all 0.35s;
  @media (max-width: 420px) {
    display: none;
  }
}
