$shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.15);

@mixin font($color, $size, $weight) {
  font-size: $size;
  color: $color;
  font-weight: $weight;
}

.title_style {
  color: var(--black-800) !important;
  font-size: 1.8rem !important;
  font-weight: 600 !important;
  margin-bottom: 0 !important;
}
.container_box {
  min-height: calc(100vh - 7rem);
  height: auto;
  width: calc(100% - 2rem);
  border-radius: 1rem;
  background: var(--white);
  scroll-behavior: smooth;
  margin: 1rem;
  padding: 1.5rem;
}
.container_style {
  min-height: calc(100vh - 7rem);
  height: auto;
  width: calc(100% - 2rem);
  border-radius: 1rem;
  scroll-behavior: smooth;
  margin: 1rem;
}

.header_style {
  display: flex;
  height: 4.5rem;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 1rem 0;
}
.button_load_style {
  background-color: var(--primary-700);
  padding: 0.4rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button_fix_style {
  height: 3rem;
  padding: 1rem 3rem;
  border-radius: 0.5rem;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  background: var(--primary-700);
  color: var(--white);

  &:disabled {
    opacity: 0.5;
  }
}

.button_cancel_fix_style {
  height: 3rem;

  padding: 0.3rem 1rem;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  background: transparent;
  color: var(--primary-700);
  border-bottom: 0.125rem solid var(--primary-700);
  &:disabled {
    opacity: 0.5;
  }
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.font_styles_card {
  font-size: 0.8rem !important;
  color: var(--gray-900) !important;
  font-weight: 400 !important;
}

.container_arrow_box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 3rem;
}

@mixin button_(
  $background: var(--primary-700),
  $color: var(--white),
  $hove_color: var(--primary)
) {
  padding: 1rem;
  color: $color;
  background: $background;
  border-radius: 0.5rem;
  width: auto;
  height: 3rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  transition: 0.3s ease-in;
  &:hover {
    filter: brightness(-1.3);
  }
}

@mixin button-copy($background, $color) {
  button {
    background: $background;
    width: auto;
    color: $color;
    padding: 0.5rem 0.5rem;
    border-radius: 8px;
    cursor: pointer;
  }
}
.input_select_blue {
  input[type='checkbox'] {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    border-radius: 0;
    outline: none;
    appearance: none;
  }
  input[type='checkbox'] {
    position: relative;
    width: 1.3rem;
    height: 1.3rem;
    cursor: pointer;
    border-radius: 0.25rem;

    &::before,
    &::after {
      position: absolute;
      display: block;
      content: '';
    }

    &::before {
      top: 0;
      left: 0;
      width: 1.1rem;
      height: 1.1rem;
      border: 1px solid var(--gray-100);
      border-radius: 0.25rem;
    }

    &::after {
      top: 0.3rem;
      left: 0.125rem;
      width: 0.9rem;
      height: 0.4rem;
      border-bottom: 0.1875rem solid var(--white);
      border-left: 0.1875rem solid var(--white);
      transform: rotate(-45deg);
      transition: opacity 0.3s;
      opacity: 0;
    }

    &:checked::after {
      opacity: 1;
    }
    &:checked::before {
      border: 1px solid var(--primary-700);
    }
    &:checked {
      opacity: 1;
      background: var(--primary-700);
    }
    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
}

.input_select_all_blue {
  input[type='checkbox'] {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    border-radius: 0;
    outline: none;
    appearance: none;
  }
  input[type='checkbox'] {
    position: relative;
    width: 1.3rem;
    height: 1.3rem;
    cursor: pointer;
    border-radius: 0.25rem;

    &::before,
    &::after {
      position: absolute;
      display: block;
      content: '';
    }

    &::before {
      top: 0;
      left: 0;
      width: 1.1rem;
      height: 1.1rem;
      border: 1px solid var(--gray-100);
      border-radius: 0.25rem;
    }

    &::after {
      top: 0.2rem;
      left: 0.125rem;
      width: 1rem;
      margin: 0 auto;
      transform: translateX(50%);
      height: 0.4rem;
      border-bottom: 0.1875rem solid var(--white);
      transform: rotate(0deg);
      transition: opacity 0.3s;
      opacity: 0;
    }

    &:checked::after {
      opacity: 1;
    }
    &:checked::before {
      border: 1px solid var(--primary-700);
    }
    &:checked {
      opacity: 1;
      background: var(--primary-700);
    }
    &:disabled {
      opacity: 0.2;
    }
  }
}
