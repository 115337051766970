/* UserTable.module.scss */
@import '../../../styles/_global';

.table-container {
  border-collapse: collapse;
  width: 100%;
  margin: 1.25rem 0;
  margin-top: 0;
}
.contract {
  text-transform: uppercase;
}
.table-container {
  border-radius: 0.5rem 0.5rem 0 0; /* Adicione a borda arredondada desejada */
  font-size: 0.7rem;
  td {
    padding: 0.6rem 0.5rem;
    vertical-align: middle;
    text-align: left;
  }
  th {
    padding: 0.8rem 0.5rem;
    vertical-align: middle;
    color: var(--white);
    font-weight: 600;
  }
  th:nth-child(8) {
    text-align: center;
  }
  th:nth-child(9) {
    text-align: center;
  }
  td:nth-child(8) {
    text-align: center;
  }
  td:nth-child(9) {
    text-align: center;
  }

  tr:nth-of-type(even) td {
    $color: rgb(226, 227, 228, 0.4);
    background-color: $color;
  }
  thead {
    background: var(--primary);
  }

  .input {
    input[type='checkbox'] {
      margin: 0;
      padding: 0;
      background: none;
      border: none;
      border-radius: 0;
      outline: none;
      appearance: none;
    }
    input[type='checkbox'] {
      position: relative;
      width: 1rem;
      height: 1rem;
      cursor: pointer;

      &::before,
      &::after {
        position: absolute;
        display: block;
        content: '';
      }

      &::before {
        top: 0;
        left: 0;
        width: inherit;
        height: inherit;
        border: 1px solid var(--gray-400);
        border-radius: 0.25rem;
      }

      &::after {
        top: 0.25rem;
        left: 0.0625rem;
        width: 0.8rem;
        height: 0.4rem;
        border-bottom: 0.1875rem solid var(--primary-700);
        border-left: 0.1875rem solid var(--primary-700);
        transform: rotate(-45deg);
        transition: opacity 0.3s;
        opacity: 0;
      }

      &:checked::after {
        opacity: 1;
      }
    }
  }
  th {
    @extend .input;
    input[type='checkbox'] {
      &::before,
      &::after {
        position: absolute;
        display: block;
        content: '';
      }

      &::before {
        top: 0;
        left: 0;
        width: inherit;
        height: inherit;
        border: 1px solid var(--gray-50);
        border-radius: 4px;
      }

      &::after {
        top: 0.25rem;
        left: 0.0625rem;
        width: 0.8rem;
        height: 0.4rem;
        border-bottom: 0.1875rem solid var(--white);
        border-left: 0.1875rem solid var(--white);
        transform: rotate(-45deg);
        transition: opacity 0.3s;
        opacity: 0;
      }

      &:checked::after {
        opacity: 1;
      }
    }
  }
  td {
    @extend .input;
  }
}

.table-container th {
  background-color: transparent;
}

.table-container th:first-child,
.table-container td:first-child {
  font-weight: bold;
}

.actions-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  height: 100%;
  width: 100%;
}
.button {
  height: 1.1875rem;
  width: 1.1875rem;
  vertical-align: top;
  border-radius: 0.2rem;
  margin-right: 0.3125rem;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 0.5;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
.edit_button {
  @extend .button;
  background-color: transparent;
}
.delete_button {
  @extend .button;
  background-color: transparent;
}

.no-users {
  font-style: italic;
  color: #777;
}

.color_error {
  color: var(--orange-text);
}
.erros {
  position: relative;

  &:hover > .card {
    cursor: pointer;
    display: flex;
    flex-direction: column;
  }
  svg {
    border-radius: 50%;
  }
}

.card {
  height: auto;
  position: absolute;
  z-index: 99999999;
  bottom: 3rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin: auto;
  display: none;
  background-color: #ffffff;
  border: 0.0625rem solid var(--gray-50);
  border-radius: 0.5rem;
  padding: 0.5rem;
  width: max-content;
  margin: 0 auto;
  text-align: center;
  &::before {
    content: '';
    position: absolute;
    bottom: -0.7rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 1.25rem;
    height: 1.25rem;
    background-color: #fff;
    border-right: 0.0625rem solid var(--gray-100);
    border-bottom: 0.0625rem solid var(--gray-100);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-radius: 0 0 4px 0;
    @media screen and (min-width: 1900px) {
      right: 4.5rem;
    }
  }

  .summary-item:not(:last-child) {
    border-bottom: 0.0625rem solid var(--gray-100);
  }
}

.summary-item {
  z-index: 99;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
  padding: 0.35rem;
  span {
    font-size: 0.8rem;
    font-weight: 600;
    color: var(--gray-400);
  }
  svg {
    border-radius: 50%;
  }
}

.amount_ {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  span {
    font-weight: 600;
    color: var(--gray-800);
  }
}

$size_circle: 0.8rem;
.circle {
  width: $size_circle;
  height: $size_circle;
  border-radius: 50%;
}
.circle_red {
  @extend .circle;
  background-color: var(--red-text);
}
.circle_green {
  @extend .circle;
  background-color: var(--green-text);
}
.circle_blue {
  @extend .circle;
  background-color: var(--blue-text);
}
.circle_orange {
  @extend .circle;
  background-color: var(--orange-text);
}
.tags {
  @extend .center;
  @include font(var(--gray-400), 0.8rem, 600);
  justify-content: center;
  align-items: center;
  position: relative;
  span {
    display: flex;
    align-items: center;
    gap: 1rem;

    p {
      background: var(--blue-50);
      color: var(--primary-700);
      padding: 0.5rem 0.5rem;
      border-radius: 50%;
      font-weight: 400;
      cursor: pointer;
    }
    p:hover + .card_tag {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      z-index: 7;
    }
    strong {
      background: var(--blue-50);
      color: var(--primary-700);
      padding: 0.5rem 0.5rem;
      border-radius: 0.5rem;
      font-weight: 400;
    }
  }
}

.card_tag {
  height: auto;
  position: absolute;
  left: 1rem;
  z-index: 2;
  bottom: 3rem;
  margin: auto;
  display: none;
  background-color: var(--white);
  border: 1px solid var(--gray-10);
  border-radius: 0.5rem;
  padding: 0.5rem;
  width: 24rem;
  margin: 0 auto;
  text-align: center;
  gap: 0.5rem;
  &::before {
    z-index: 1;
    content: '';
    position: absolute;
    bottom: -0.6rem;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 0.75rem;
    height: 0.75rem;
    background-color: var(--white);
    border-right: 1px solid var(--gray-10);
    border-bottom: 1px solid var(--gray-10);
    border-top-left-radius: 0.1875rem;
    border-top-right-radius: 0.1875rem;
    border-radius: 0 0 0.25rem 0;
  }
}
