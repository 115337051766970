.container {
  padding: 3rem 1rem;
  border-radius: 0.5rem;
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}

.item {
  background: var(--white);
  padding: 1rem 1rem 2rem 1rem;
  border-radius: 1rem;
  height: 10rem;
  width: 14rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  position: relative;
  transition: ease 0.7s;
  &:hover {
    cursor: pointer;
  }
  p {
    position: absolute;
    bottom: 0.1rem;
    color: var(--primary);
    font-weight: 500;
    font-size: 1rem;
    text-align: center;
  }
}
