@import '../../styles/styles';
.container {
  height: 100vh;
  width: 100%;
  background: var(--primary);
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  border-radius: 8px;
  background: var(--white);
  box-shadow: $shadow;
  width: 45rem;
  height: 25rem;
  padding: 2rem 2rem;
  h1 {
    font-weight: bold;
    color: var(--gray-850);
  }
}

.button {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 1rem;
  width: 100%;
  padding: 1rem 1rem;
  background: var(--primary-700);
  color: var(--white);
  border-radius: 0.5rem;
  transition: 0.7s ease;
  &:hover {
    background: var(--primary-700);
  }
}

.wrapper_button {
  display: flex;
  gap: 1rem;
  a {
    text-align: center;
  }
}

.button_nav {
  font-size: 1rem;
  width: 16rem;
  padding: 1rem 1rem;
  background: var(--white);
  color: var(--primary);
  border-radius: 0.5rem;

  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.7s ease;
}
