.container {
  background: var(--white);
  display: flex;
  align-items: center;
  width: auto;
  height: 2.5rem;
  font-size: 0.85rem;
  padding: 0 1rem;
  justify-content: space-between;
  border-radius: 0.25rem;
  color: var(--gray-400);
  cursor: pointer;
  position: relative;
  border: 1px solid var(--gray-50);
}
.menu {
  position: absolute;
  top: 2.5rem;
  right: 0;
  width: 12rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 0.5rem;
  padding: 0.5rem;

  background: var(--white);
  box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.15);
  z-index: 999;
}

.button__menu {
  color: var(--gray-500);
  border-radius: 0.25rem;
  padding: 0 1rem;
  height: 2.5rem;
  background: var(--gray-10);
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  transition: 0.3s ease;
  font-size: 0.9rem;
  &:hover {
    background: var(--gray-50);
    color: var(--gray-600);
  }
  z-index: 9999;
  span {
    margin-right: auto;
  }
}
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9;
  background: transparent;
  cursor: auto;
}
