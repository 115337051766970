@import '../../styles/_global';

.sectionContainer {
  @extend .container_box;
}

.upload {
  display: flex;
  width: 100%;
  flex-direction: column;
  .buttonContainer {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
  }
}

.wrapper__buttons {
  display: flex;
  gap: 1rem;
}
.wrapper_pagination {
  width: 100%;
  display: flex;
  height: 2rem;
  justify-content: space-between;
  align-items: center;
}
.description_order {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding-left: 2rem;
  gap: 1rem;
  span {
    display: flex;
    background: var(--gray-50);
    padding: 0.5rem 1rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    border-radius: 0.5rem;

    font-size: 0.8rem;
    font-weight: 600;
    gap: 1rem;
    p {
      font-weight: 400;
    }
  }
}
.button__affect {
  svg {
    color: var(--primary);
  }

  button {
    &:disabled {
      opacity: 0.5;
      cursor: no-drop;
    }
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-right: 1rem;
}
.reload {
  padding: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 0.25rem;
  background-color: var(--primary);

  &:disabled {
    cursor: not-allowed;
    opacity: 0.2;
  }
}
.aprove {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  height: 2.5rem;
  border-radius: 0.25rem;
  font-weight: 400;
  font-size: 0.8rem;
  background-color: var(--primary);
  gap: 0.5rem;
  padding: 1rem 1.5rem;
  color: var(--white);
  &:hover {
    opacity: 0.9;
  }
}
.contentLoad {
  border-radius: 0.5rem;
}

.send {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  border-radius: 4px;
}

.upload {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
}

.button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cardMovements {
  border: 1px solid var(--gray-100);
  margin: 0 2rem 0.5rem 2rem;
  border-radius: 0.5rem;

  div:nth-child(1) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }
  div:nth-child(2) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--gray-100);
    padding: 1rem;
  }
  svg {
    cursor: pointer;
  }
}
.font {
  font-size: 0.8rem;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.heading__cards {
  @extend .center;
  @extend .font;
  color: var(--gray-600);
  font-weight: 600;
}
.heading__status {
  @extend .center;
  @extend .font;
  color: var(--gray-600);
  font-weight: 600;
  padding: 0 1rem;
}

.arowPages {
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 600px) {
    padding: 10px;
    width: 300px;
    height: 100px;
    flex-direction: column;
  }
}
.textPage {
  font-size: 1rem;
  margin-right: 0.5rem;
  color: var(--gray-900);
}
.loading {
  cursor: wait;
  opacity: 0.6;
  height: 100vh;
}

.back {
  display: flex;
  align-items: center;
  width: 100px;
  cursor: pointer;
  justify-content: space-around;
}
.wrapper__selection {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.wrapper_resume {
  display: flex;
  justify-content: space-between;
}
.listLoader {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-end;
}
.status {
  padding: 0.01rem;
}

.message {
  width: calc(100% - 7.3rem);
  margin-left: 1rem;
  background-color: var(--purple-400);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  color: var(--white);
  height: 1.1rem;
  position: absolute;
  border-radius: 0 0 1rem 1rem;
}

.error {
  width: calc(100% - 9.3rem);
  margin-left: 1rem;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  color: var(--white);
  height: 1.1rem;
  position: absolute;
  border-radius: 0 0 1rem 1rem;
}
@media (max-width: 420px) {
  .container {
    width: 100%;
    margin-left: -2rem;
    height: auto;
    main {
      margin: 0;
    }
  }
  .upload {
    padding: 1rem 0 1rem 0;
  }
  .arowPages {
    margin: 0;
    margin-bottom: 4rem;
    width: 100%;
    align-items: flex-end;

    input {
      display: none;
    }
  }
  .back {
    display: flex;
    justify-content: flex-start;
    margin-left: 0.5rem;
    span {
      display: none;
    }
  }
  .cardHeader {
    display: none;
  }
  .input {
    width: 100%;
    margin: 1rem 0 1rem 0;
  }
  .wrapper_pages {
    margin-bottom: 3rem;
    margin-top: 1rem;
  }
  .message {
    width: 100%;
    margin-left: 1rem;
    background-color: var(--purple-400);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    color: var(--white);
    height: 1.1rem;
    position: absolute;
    border-radius: 0 0 1rem 1rem;
  }

  .error {
    width: 100%;
    margin-left: 1rem;
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    color: var(--white);
    height: 1.1rem;
    position: absolute;
    border-radius: 0 0 1rem 1rem;
  }
}
