.card {
  padding: 0 1rem;
  display: flex;
  align-items: center;
  font-family: Arial, sans-serif;
}
.total,
.quantity {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.amount {
  font-size: 1.2rem;
  color: var(--primary-700); /* Cor azul escura */
  font-weight: bold;
}

.label {
  font-size: 0.75rem;
  color: #888; /* Cor cinza */
  margin-top: 4px;
}

.divider {
  width: 1px;
  height: 2rem;
  background-color: #ccc; /* Cor cinza clara */
  margin: 0 20px;
}

.qty {
  font-size: 1.2rem;
  color: #000;
  font-weight: bold;
}
