@import '../../../../styles/_global';

.container {
  position: relative;
  width: 100%;
  height: 3.5rem;

  box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.15);
  background: var(--white);
  border-radius: 1rem;
  display: grid;
  grid-template-columns: 1.5rem 1fr 2rem 10rem 7rem 16rem 10rem 6rem 2rem;
  gap: 1rem;
  padding: 0 1rem;
}

.type {
  @extend .center;
  @extend .font_styles_card;

  justify-content: center;

  span {
    padding: 0.5rem 0.5rem;
    border-radius: 50%;
  }
}

.tags {
  position: relative;
  @extend .center;
  @extend .font_styles_card;

  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  span {
    display: flex;
    align-items: center;
    gap: 1rem;

    p {
      background: var(--blue-50);
      color: var(--primary-700);
      padding: 0.5rem 0.5rem;
      border-radius: 50%;
      font-weight: 400;
      cursor: pointer;
    }
    p:hover + .card {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      z-index: 7;
    }
    strong {
      background: var(--blue-50);
      color: var(--primary-700);
      padding: 0.5rem 0.5rem;
      border-radius: 0.5rem;
      font-weight: 400;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      button {
        background: transparent;
        cursor: pointer;
      }
    }
  }
}
.key {
  @extend .font_styles_card;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @include button-copy(var(--gray-50), var(--gray-600));
}
.plus {
  background: var(--gray-10);
  color: var(--primary-700);
  padding: 0.2rem 0.2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.eyes {
  @extend .center;
  cursor: pointer;
}
.date {
  @extend .center;
  @extend .font_styles_card;
  font-weight: 600;
}

.phone {
  @extend .center;
  @extend .font_styles_card;
  font-weight: 600;
}

.document {
  @extend .center;
  @extend .font_styles_card;

  justify-content: center;
}

.debit {
  @extend .center;
  @extend .font_styles_card;

  color: var(--red-500);
  font-weight: 600;
  justify-content: end;
}

.name {
  @extend .center;
  justify-content: start;

  span {
    @extend .center;
    @extend .font_styles_card;

    font-weight: 600;
    text-transform: capitalize;
    transition: 0.3s ease-in;

    &:hover {
      color: var(--gray-600);
    }
  }
}

.status {
  @extend .center;

  justify-content: center;
  position: relative;
  cursor: pointer;

  div {
    display: none;
    position: absolute;
    margin: -6.7rem auto auto -12rem;
    min-width: 20rem;
    background-color: var(--orange-status);
    padding: 0.35rem;
    border-radius: 0.5rem;

    p {
      display: flex;
      max-height: 3rem;
      overflow: auto;
      flex-direction: column;
      flex-wrap: wrap;
      width: 100%;
      background-color: var(--white);
      color: var(--black-800);
      padding: 0.5rem;
      font-weight: 400;
      border-radius: 0.24rem;
      z-index: 7;

      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }

      &::-webkit-scrollbar-track {
        background-color: var(--white);
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--gray-50);
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: var(--gray-100);
      }
    }

    &::before {
      content: '';
      width: 0.75rem;
      height: 0.75rem;
      background-color: var(--orange-status);
      position: absolute;
      border-radius: 0 0 0.25rem 0;
      bottom: -0.3rem;
      left: 80%;
      transform: translateX(-50%) rotate(45deg);
      z-index: 1;
    }
  }

  &:hover {
    div[reason] {
      display: flex;
    }
  }
}

.bond {
  @extend .center;
  @extend .font_styles_card;

  color: var(--gray-600);
  font-weight: 600;
  justify-content: center;
}

.wrapper__Switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  //  margin-left: 1rem;
  cursor: pointer;
}

.loading {
  width: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;

  div {
    display: flex;
    width: 4rem;
  }

  // margin-right: 1rem;
}

.info {
  color: var(--gray-600);

  a {
    @extend .center;
    background: var(--gray-50);
    width: 2rem;
    height: 2rem;
    padding: 0;
    margin: 0;
    border-radius: 0.5rem;
    transition: 0.3s ease-in;

    &:hover {
      background-color: var(--gray-75);
    }
  }
}
.input {
  display: flex;
  align-items: center;
  justify-content: center;
  @extend .input_select_blue;
}
.card {
  height: auto;
  position: absolute;
  left: 2.5rem;
  z-index: 2;
  bottom: 3rem;
  margin: auto;
  display: none;
  background-color: var(--white);
  border: 1px solid var(--gray-50);
  border-radius: 0.5rem;
  padding: 0.5rem;
  width: 24rem;
  margin: 0 auto;
  text-align: center;
  gap: 0.5rem;

  strong {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &::before {
    z-index: 1;
    content: '';
    position: absolute;
    bottom: -0.6rem;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 0.75rem;
    height: 0.75rem;
    background-color: var(--white);
    border-right: 1px solid var(--gray-10);
    border-bottom: 1px solid var(--gray-10);
    border-top-left-radius: 0.1875rem;
    border-top-right-radius: 0.1875rem;
    border-radius: 0 0 0.25rem 0;
  }

  &::after {
    z-index: 1;
    content: '';
    position: absolute;
    bottom: -2rem;
    left: 8rem;
    margin: auto;
    width: 8rem;
    height: 4rem;
    background-color: transparent;
    border-right: 1px solid var(--gray-10);
    border-bottom: 1px solid var(--gray-10);
    border-top-left-radius: 0.1875rem;
    border-top-right-radius: 0.1875rem;
    border-radius: 0 0 0.25rem 0;
    cursor: pointer;
  }
  &:hover {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    z-index: 7;
  }
}
