@import '../../styles/styles';

.wrapper__container {
  width: calc(100% - 2rem);
  min-height: calc(100vh - 6rem);
  height: auto;
  margin: 1rem;
  margin-bottom: auto;
  background: var(--white);
  border-radius: 1rem;
  padding: 1rem;

  @media (max-width: 420px) {
    grid-template-columns: 1fr;
    height: 100vh;
  }
}
.container {
  width: 100%;
  @media (max-width: 420px) {
    grid-template-columns: 1fr;
    height: 100vh;
  }
}
.title {
  font-weight: bold;
  color: var(--gray-950);
  margin-bottom: 1rem;
  font-size: 1.5rem;
  margin-left: 1rem;
}
.info {
  margin-bottom: 2rem;
  h3 {
    @extend .title;
    padding: 0 0.5rem;
    font-size: 1rem;
    margin-left: 0.5rem;
    margin-top: 1rem;
  }
  li {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-start;
    align-items: center;

    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    border-bottom: 1px solid var(--gray-50);
    p {
      color: var(--gray-800);
      margin-top: 0;
    }
    strong {
      color: var(--gray-800);

      font-weight: 600;
    }
  }
}
.wrapper__profile {
  display: flex;
  width: 100%;
  height: 16rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  @media (max-width: 420px) {
    height: 100px;
    margin-bottom: 2rem;
  }
}

.wrapper__itens {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
  padding: 0.5rem 0.5rem;

  height: 2rem;
  border-bottom: 1px solid var(--gray-100);

  p {
    color: var(--gray-500);
    margin-top: 0;

    &:nth-child(1) {
      font-weight: 600;
    }
  }
}
.profile {
  margin-right: 1rem;
  border: 1px solid var(--primary);
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;

  div:nth-child(1) {
    text-transform: uppercase;
    font-size: 5rem;
    display: flex;
    justify-content: flex-end;
  }
  label {
    padding: 0.2rem;
    border-radius: 0.25rem;
    background: var(--primary);
    top: 8rem;

    position: absolute;
    margin-top: 0.25rem;
    color: var(--white);
    font-size: 1.5rem;
    text-transform: unset;
    display: flex;
    justify-content: flex-end;
  }

  @media (max-width: 420px) {
    width: 4rem;
    height: 4rem;
    div:nth-child(1) {
      font-size: 2rem;
    }
    label {
      top: 2.5rem;
      font-size: 0.9rem;
    }
  }
}

.description {
  margin: 0 0 0 0;
  padding: 2rem 2rem;
  width: auto;
  height: auto;

  border-radius: 0.5rem;
  // box-shadow: $shadow;
  margin-bottom: 1rem;
  h1 {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--gray-600);
    text-transform: capitalize;
    margin-bottom: 1rem;
    border: 1px inherit var(--white);

    border-bottom-color: var(--gray-800);
  }
}

.wrapper__button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.button__plus {
  padding: 0.5rem 1rem;
  color: var(--white);
  background: var(--primary-700);
  border-radius: 0.5rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8rem;
  font-weight: bold;
  p {
    padding-right: 1rem;
  }
}
.input {
  border-radius: 0.5rem;
  padding: 0.3rem 0.7rem;
  border: 1px solid var(--gray-100);
  width: max-content;
  min-width: 16rem;

  &:focus {
    border: 1px solid var(--gray-200);
  }
}
.button__plus_ {
  padding: 0.5rem 1rem;
  color: var(--red-500);
  background: var(--white);
  border-bottom: 2px solid var(--red-500);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8rem;
  font-weight: bold;
  p {
    padding-right: 1rem;
  }
}
.button__edit_ {
  background: transparent;
}

@keyframes animeLeft {
  to {
    opacity: 1;
    transform: initial;
  }
}

.isNotLogin {
  display: flex;
  align-items: center;
  position: relative;
}

.icon {
  position: absolute;
  top: -12px;
  right: -20px;
  cursor: pointer;
}
.logo {
  width: 10rem;
  height: 4rem;
  margin-bottom: 1.5rem;
}

.template {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}
