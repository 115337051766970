@import '../../../../styles/_global';
.container {
  width: 100%;
  height: 3.8rem;
  margin: 0.5rem 0;

  box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.15);
  background: var(--white);
  border-radius: 1rem;
  display: grid;
  grid-template-columns: 1.2fr 0.5fr 1.4fr 0.5fr 150px 50px;

  padding: 0 1rem;

  li {
    display: flex;
    align-items: center;
  }
}
.container_ {
  width: 100%;
  height: 3.5rem;
  margin: 0.5rem 0;
  box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.15);

  background: var(--white);
  border-radius: 1rem;
  display: grid;
  grid-template-columns: 1.2fr 0.5fr 1.4fr 0.5fr 150px;
  align-items: center;
  padding: 0 1rem;
}

.font {
  @extend .font_styles_card;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.date {
  text-align: center;
  font-weight: 600;
  display: flex;
  align-items: center;
  @extend .font;
}

.email {
  @extend .font;
}
.document {
  @extend .center;
  @extend .font;
}
.name {
  @extend .center;
  @extend .font;
  cursor: pointer;
}

.wrapper__Switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  //  margin-left: 1rem;
  cursor: pointer;
}

.loading {
  width: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;

  div {
    display: flex;
    width: 4rem;
  }
  // margin-right: 1rem;
}

@media screen and (max-width: 420px) {
  .container {
    height: auto;
    width: 100%;
    margin: 1rem auto;
    padding: 0 1rem;
    background: var(--white);
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.15);

    cursor: pointer;
    div {
      padding-bottom: 1rem;
      display: flex;
      flex-direction: column;
      width: 100%;
      ul {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-left: 0;
        padding: 0;
        li {
          display: flex;
          margin-top: 1rem;
          width: 100%;

          span {
            margin-left: 0;

            text-align: center;
            width: 100%;
            font-size: 0.6rem;
          }
        }
      }
    }
  }
  #salary {
    text-align: center;
    font-size: 0.8rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &::before {
      content: 'Salário:';
      font-weight: bold;
      font-size: 1rem;
      color: var(--gray-800);
      padding-right: 0.5rem;
    }
  }
  #limit {
    text-align: center;
    font-size: 0.8rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &::before {
      content: 'Limite:';
      font-weight: bold;
      font-size: 1rem;
      color: var(--gray-800);
      padding-right: 0.5rem;
    }
  }

  #debit {
    text-align: center;
    font-size: 0.8rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &::before {
      content: 'Debito:';
      font-weight: bold;
      font-size: 1rem;
      color: var(--gray-800);
      padding-right: 0.5rem;
    }
  }
  #date {
    text-align: center;
    font-size: 0.8rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &::before {
      content: 'Admissão:';
      font-weight: bold;
      font-size: 1rem;
      color: var(--gray-800);
      padding-right: 0.5rem;
    }
  }
  #document {
    color: var(--gray-900);
    font-size: 0.8rem;
    text-align: center;
    margin-right: 0;
    width: 100%;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    &::before {
      content: 'Documento:';
      font-weight: bold;
      font-size: 1rem;
      color: var(--gray-800);
      padding-right: 0.5rem;
    }
  }
  .wrapper__Switch {
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: flex-end;
  }
  .loading {
    width: auto;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2rem;
      transform: rotate(90deg);
      span {
        padding: 0.4rem;
      }
    }
    // margin-right: 1rem;
  }
}
