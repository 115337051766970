@import '../../../../styles/_global';

.container {
  position: relative;
  width: 2rem;
  height: 3rem;

  background: var(--white);
  border-radius: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper_buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.button {
  padding: 1rem;
  color: var(--white);
  background: var(--primary-700);
  border-radius: 0.5rem;
  width: auto;
  max-width: 12rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  &:disabled {
    opacity: 0.7;
  }
}
.button_close {
  padding: 0.5rem;
  color: var(--white);
  background: transparent;
  border-radius: 0.5rem;
  width: auto;
  display: flex;
  gap: 1rem;
  align-items: center;
  &:disabled {
    opacity: 0.7;
  }
}
.card {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 46rem;
  margin: auto;
  width: auto;
  max-width: 36rem;
  z-index: 10001;
  background: var(--white);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  gap: 1rem;
  box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.15);
}

.item {
  border: 1px solid var(--gray-50);
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 3.5rem;
  padding: 0 1rem;
  background: var(--white);
}

.wrapper_items {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  overflow-y: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9999;
  background: transparent;
  cursor: auto;
}

.menu {
  position: absolute;
  top: 2.5rem;
  right: 0;
  width: 16rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  z-index: 10000;

  border-radius: 0.5rem;
  padding: 0.7rem;

  background: var(--white);
  box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.15);

  li {
    background-color: var(--gray-10);
    border-radius: 0.5rem;
    padding: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.3s ease-in;
    cursor: pointer;
    &:hover {
      background-color: var(--gray-50);
    }
  }
}
