@import '../../styles/_global';

.wrapper__container {
  color: var(--gray-800);
  width: 100%;
  @media (max-width: 420px) {
    grid-template-columns: 1fr;
    height: 100vh;
  }
  h1 {
    text-transform: capitalize;
    font-weight: 600;
    padding: 1rem 0 2rem 0;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    border-radius: 0.2rem; /* Borda redonda para a tabela */
    overflow: hidden; /* Garante que as bordas redondas sejam aplicadas corretamente */
  }
  table,
  th,
  td {
    border: none;
    padding: 10px 10px;
    color: var(--gray-800);
  }
  tbody tr:nth-child(even) {
    background-color: var(--gray-10);
  }
  th {
    background-color: var(--gray-50);
    font-weight: bold;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
  }

  tr > th:not(:first-child) {
    text-align: center;
  }
  tr > td:not(:first-child) {
    font-weight: bold;
    text-align: center;
  }
  @media screen and (max-width: 767px) {
    table,
    thead {
      display: none !important;
    }

    table,
    tr,
    td {
      display: block;
      width: 100%;
      float: right;
    }

    table tr {
      margin-bottom: 15px;
    }

    table td {
      text-align: right;
      padding-left: 40%;
    }
    table td::before {
      content: attr(data-name);
      position: absolute;
      left: 0;
      width: 50%;
      text-align: left;
      padding-left: 15px;
      font-size: 0.9rem;
      font-weight: 600;
    }
  }
}
