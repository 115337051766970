.wrapper {
  width: 100%;
  display: flex;
  padding: 0.5rem 0;
  flex-direction: column;

  label {
    font-size: 1rem;
    color: var(--gray-800);

    strong {
      font-weight: 600;
    }
  }
  input {
    margin: 0.3rem 0;
    width: 100%;
    border: 1px solid var(--gray-200);
    height: 3rem;
    padding: 0 1rem;
    background: var(--white);
    border-radius: 0.5rem;
    font-size: 0.875rem;
    &:disabled {
      opacity: 0.7;
    }

    :focus {
      border-color: var(--gray-50);
      transition: all 0.1s ease;
    }
    &:hover {
      border: 2px solid var(--gray-50);
      transition: all 0.1s ease;
    }
  }
  input::placeholder {
    color: var(--gray-200);
  }

  @media screen and (max-width: 767px) {
    height: 4rem;

    input {
      margin: 0;
    }
  }
}
.label_form {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-bottom: 0.5rem;
}
.error__input {
  color: var(--red-500);
  font-size: 0.7rem;

  @media screen and (max-width: 767px) {
    margin-top: 0.5rem;
  }
}
